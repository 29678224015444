import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Data, Router } from '@angular/router';
import { AppendOrderModel } from 'src/app/core/model/trial-order.model';
import { CreateTrailsService } from 'src/app/services/event-and-orders/create-trails.service';
import { MatDialog } from '@angular/material/dialog';
import { PerformanceService } from 'src/app/services/suppliers/performance.service';
import { WarningPopupComponent } from 'src/app/core/dialogs/warning-popup/warning-popup.component';
import { UserService } from 'src/app/services/user.service';
import { SupplierListMap } from 'src/app/core/model/performance.model';

@Component({
  selector: 'app-create-trails',
  templateUrl: './create-trails.component.html',
  styleUrls: ['./create-trails.component.scss'],
})
export class CreateTrailsComponent implements OnInit {
  data: Data = {} as Data;
  eventId: string | undefined;
  ordernumber: string | undefined;
  trackingnumber = ''
  psmsRouting = ''
  supplierCodes: SupplierListMap[] = [];
  suppliercode = ''
  suppliername = ''
  comment = ''
  kanbanNumber = ''
  partNumber = ''
  partName = ''
  plant1Qty = ''
  plant2Qty = ''
  plant3Qty = ''
  totalQuantity = ''
  storeAddress = ''
  lineSideAdress = ''
  specialistName = ''
  specialistCode = ''
  namcDate = ''
  disableSave = false;
  isSaveDisabled = false;
  supplierPickupDate = ''
  shippingDateTime = ''
  manifestNumber = ''
  lifeCycleCode = ''
  palletCode = ''
  mandatoryFieldError = 'This field is mandatory';
  constructor(
    private readonly cdr: ChangeDetectorRef,
    private readonly route: ActivatedRoute,
    private readonly CreateTrailsService: CreateTrailsService,
    private readonly router: Router,
    private readonly userService: UserService,
    private readonly performanceService: PerformanceService,
    private readonly dialog: MatDialog,
  ) { }
  orderQuantities = [
    { name: 'TRIALQTY', quantity: 0 },
    { name: 'ASSYTRAINQTY', quantity: 0 },
    { name: 'CFQTY', quantity: 0 },
    { name: 'PEQTY', quantity: 0 },
    { name: 'QEQTY', quantity: 0 },
    { name: 'AXWQTY', quantity: 0 }
  ];
  isViewMode = false;
  editMode = false
  response: AppendOrderModel = {} as AppendOrderModel;
  defaultQuantities: string[] = [];
  selectedOption: string | null = null;
  userId!: string;
  createdBy!: string;
  errorMessage = '';
  mandatoryFieldErrors = {
    partNumber: '',
    partName: '',
    supplierCode: '',
    namcDueDate: '',
    supplierPickUp: '',
    totalQuantity: ''
  }
  validdaterror = {
    nameduedate: '',
    pickupdate: ''
  }
  dateErrors = {
    namcDate: '',
    supplierPickupDate: ''
  };
  lengthError = {
    partNumbererror: ''
  }
  totalErrorMessage = '' 
  validDate(): boolean {
    let ifValid = true;
    this.validdaterror.nameduedate = this.namcDate.trim() ? '' : this.mandatoryFieldError;
    this.validdaterror.pickupdate = this.supplierPickupDate.trim() ? '' : this.mandatoryFieldError;
    if (!this.namcDate.trim() || !this.supplierPickupDate.trim()) {
      ifValid = false
    }
    return ifValid
  }
  validateQuantities(): boolean {
    let isValid = true;
    const hasValidQuantity = this.orderQuantities.some((item) => item.quantity > 0);

    if (!hasValidQuantity) {
      this.errorMessage = 'At least 1 Quantity Name is Required';
      isValid = false;
    } else {
      this.errorMessage = '';
    }
    if (this.partNumber.length !== 12) {
      this.lengthError.partNumbererror = 'Part Number should be always 12 characters long'
    }
    else {
      this.lengthError.partNumbererror = ''
    }
    this.mandatoryFieldErrors.partNumber = this.partNumber.trim() ? '' : this.mandatoryFieldError;
    this.mandatoryFieldErrors.partName = this.partName.trim() ? '' : this.mandatoryFieldError;
    this.mandatoryFieldErrors.supplierCode = this.suppliercode.trim() ? '' : this.mandatoryFieldError;
    this.mandatoryFieldErrors.totalQuantity = this.totalQuantity.trim() ? '' : this.mandatoryFieldError;

    if (
      !this.partNumber.trim() ||
      !this.partName.trim() ||

      !this.suppliercode.trim() ||
      !this.totalQuantity?.trim()
    ) {
      isValid = false;
    }

    if (Number(this.totalQuantity) !== this.calculateTotalQuantity()) {
      this.totalErrorMessage = 'Total Qty must match the sum of all Quantity Names values';
      isValid = false;
    } else {
      this.totalErrorMessage = '';
    }

    return isValid;
  }

  calculateTotalQuantity(): number {
    return this.orderQuantities.reduce((sum, item) => sum + Number(item.quantity || 0), 0);
  }
  isInvalidFutureDate(_date: string): boolean {
    this.dateErrors = this.dateErrors || {};
    let errordate = true
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);
    const supplierPickupDate = new Date(this.supplierPickupDate);
    const orderDeliveryDate = new Date(this.namcDate);
    if (supplierPickupDate <= currentDate) {
      this.dateErrors.supplierPickupDate =
        'Selected date is invalid (it can not be present or past date)';
      errordate = false
    } else {
      this.dateErrors.supplierPickupDate = '';
    }
    if (orderDeliveryDate <= currentDate) {
      this.dateErrors.namcDate =
        'Selected date is invalid (it can not be present or past date)';
      errordate = false
    } else {
     this.dateErrors.namcDate = '';
    }
    return errordate
  }
  ngOnInit(): void {
    this.eventId = String(history.state?.myData);
    this.userService.userDetails?.subscribe((value) => {
      if (value !== null) {
        this.userId = value.userId;
      }
    });
    this.isViewMode = true
    this.CreateTrailsService.getQuantityNames(this.eventId).subscribe((res) => {
      this.response = res
      this.ordernumber = this.response.trialOrder.orderNumber

      this.defaultQuantities = res.trialEventQuantityNameLst.map((element) => {
        return element.qNameBox;
      });
    });
    this.fetchSupplierCodes();
  }
  toggleSelection(option: string) {
    if (this.selectedOption === option) {
      this.selectedOption = null;
    } else {
      this.selectedOption = option;
    }
  }
  fetchSupplierCodes(): void {

    this.performanceService.suppliersDropDown()?.subscribe({
      next: (res) => {

        setTimeout(() => {
          this.supplierCodes = res;
        });
      },
      error: (_error: Error) => {
        this.errorMessage = 'Error fetching supplier codes';
      },
    });
  }
  onSupplierCodeChange(selectedCode: string): void {
    const selectedSupplier = this.supplierCodes.find(
      (supplier) => supplier.supplierCode === selectedCode
    );
    this.suppliername = selectedSupplier ? selectedSupplier.supplierName : '';
  }
  convertToNumber(value: string): number {
    return value ? parseInt(value, 10) : 0;
  }
  mapOrderQuantities(): void {
    this.orderQuantities.forEach((item) => {
      if (this.data.hasOwnProperty(item.name)) {
        item.quantity = Number(this.data[item.name]);
      } else {
        item.quantity = 0;
      }
    });
  }
  onSubmit(): void {
    const showerror = this.isInvalidFutureDate(this.supplierPickupDate)
    const validerror = this.validateQuantities()
    const validdateerror = this.validDate()

    if ((!showerror) || (!validdateerror) || (!validerror)) {
      return
    }
    else {

      const payload = {
        eventId: this.eventId,
        psmsFromRouting: this.response.psmsFromRouting,
        psmsToRouting: this.response.psmsFromRouting,
        appendDeliveryDate: this.namcDate,
        appendSupplierPickupDate: this.supplierPickupDate,
        appendWorkOrderNumber: this.response.appendWorkOrderNumber,
        appendLifeCycleCode: this.response.appendLifeCycleCode,
        trialEventQuantityNameLst: this.response.trialEventQuantityNameLst,
        appendSendToSapFlag: this.response.appendSendToSapFlag,
        calculateOrderNumber: this.response.calculateOrderNumber,
        trialOrder: {
          statusId: this.response.trialOrder.statusId,
          orderNumber: this.response.trialOrder.orderNumber,
          promiseDate: null,
          isArchiveFlag: this.response.trialOrder.isArchiveFlag,
          totalOrderQuantity: this.totalQuantity,
          namcComent: this.comment,
          supplierPickupDate: this.response.appendSupplierPickupDate,
          supplierCode: this.suppliercode,
          supplierName: this.suppliername,
          kanbanNumber: this.kanbanNumber,
          partNumber: this.partNumber,
          partName: this.partName,
          plant1Qty: this.convertToNumber(this.plant1Qty,),
          plant2Qty: this.convertToNumber(this.plant2Qty),
          plant3Qty: this.convertToNumber(this.plant3Qty),
          deliveryDueDate: this.response.appendDeliveryDate,
          manifestNumber: this.manifestNumber,
          completionDate: this.response.trialOrder.eventId.completionDate,
          supplierComment: "",
          storeAddress: this.storeAddress,
          lineSideAddress: this.lineSideAdress,
          poTrackingNumber: this.trackingnumber,
          workOrderNumber: this.response.appendWorkOrderNumber,
          lifeCycleCode: this.lifeCycleCode,
          owkLabelLine1: "",
          owkLabelLine2: "",
          sendToSapFlag: this.selectedOption,
          aribaPrNumber: "",
          aribaOrderStatus: "",
          aribaOrderComment: "",
          poLineNo: "",
          requestForSuppShipDtChng: "",
          requestForSuppShipcmmntChng: "",
          palletCode: this.palletCode,
          naPipeStatus: "",
          eventId: {
            eventId: this.eventId,
            eventTypeId: this.response.trialOrder.eventId.eventTypeId,
            namcCode: this.response.trialOrder.eventId.namcCode,
            projectCode: this.response.trialOrder.eventId.projectCode,
            eventName: this.response.trialOrder.eventId.eventName,
            partsSource: this.response.trialOrder.eventId.partsSource,
            eventDescription: this.response.trialOrder.eventId.eventDescription,
            psmsFromRouting: this.response.trialOrder.eventId.psmsFromRouting,
            psmsToRouting: this.response.trialOrder.eventId.psmsToRouting,
            shipmentLocation: this.response.trialOrder.eventId.shipmentLocation,
            shipmentAddress: this.response.trialOrder.eventId.shipmentAddress,
            shipmentCity: this.response.trialOrder.eventId.shipmentCity,
            shipmentState: this.response.trialOrder.eventId.shipmentState,
            shipmentCountry: this.response.trialOrder.eventId.shipmentCountry,
            shipmentPostalCode: this.response.trialOrder.eventId.shipmentPostalCode,
            dockCode: this.response.trialOrder.eventId.dockCode,
            orderNumberSeries: this.response.trialOrder.eventId.orderNumberSeries,
            logisticProvider: this.response.trialOrder.eventId.logisticProvider,
            shipmentScheduledTracking: this.response.trialOrder.eventId.shipmentScheduledTracking,
            assignQPCToTrial: this.response.trialOrder.eventId.assignQPCToTrial,
            subRouteNameOverride: this.response.trialOrder.eventId.subRouteNameOverride,
            mainRouteNameOverride: this.response.trialOrder.eventId.mainRouteNameOverride,
            storesAddressNameOverride: this.response.trialOrder.eventId.storesAddressNameOverride,
            carryoverPartsOrdering: this.response.trialOrder.eventId.carryoverPartsOrdering,
            completionDate: this.response.trialOrder.eventId.completionDate,
            packagingNeed: this.response.trialOrder.eventId.packagingNeed,
            eventStatus: this.response.trialOrder.eventId.eventStatus,
            ringiNumber: this.response.trialOrder.eventId.ringiNumber,
            isNAQD: this.response.trialOrder.eventId.isNAQD,
            trialEventQuantityNameLst: [],
          },
          createdBy: this.userId,
          createTimestamp: this.response.trialOrder.createTimestamp,
          revisionDate: this.response.trialOrder.revisionDate,
        },
        quantityNames: this.defaultQuantities,
        quantityNamesQty: this.orderQuantities.map(item => item.quantity)
      };

      this.CreateTrailsService.oncreateTrails(payload).subscribe({
        next: (res) => {

          this.router.navigateByUrl('/dummy', { skipLocationChange: true }).then(() => {
            this.router.navigate(['event-order/trial-orders/trialOrderList'], {
              state: { res, myData: this.eventId, dummy: true },
            });
          });
        },
      })
    }
  }
  cancel() {

    this.dialog
      .open(WarningPopupComponent, {

      })
  }

}