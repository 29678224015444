import { Injectable } from '@angular/core';
import { TrialOrderDetails } from 'src/app/core/model/trial-order.model';
import { ExcelService } from '../excel.service';

@Injectable({
  providedIn: 'root',
})
export class ExportOrdersService {
  constructor(private readonly excelService: ExcelService) {}
  orderStatusHeader = 'ORDER STATUS';
  orderTrackingHeader = 'ORDER TRACKING NO';
  psmsRoutingHeader = 'PSMS ROUTING';
  orderTypeHeader = 'ORDER TYPE';
  eventNameHeader = 'EVENT NAME';
  specialistNameHeader = 'SPECIALIST NAME';
  specialistCodeHeader = 'SPECIALIST CODE';
  supplierCodeHeader = 'SUPPLIER CODE';
  namcDueHeader = 'NAMC DUE DATE';
  supPickupHeader = 'SUP PICKUP DATE';
  supShipHeader = 'SUP SHIP DATE';
  supNameHeader = 'SUPPLIER NAME';
  namcComHeader = 'NAMC COMMENT';
  storeAddHeader = 'STORES ADDRESS';
  lineAddHeader = 'LINESIDE ADDRESS';
  supComHeader = 'SUPPLIER COMMENT';
  manifestNbrHeader = 'MANIFEST NUMBER';
  receiverNbrHeader = 'RECEIVER NUMBER';
  eciNumberHeader = 'ECI NUMBER';
  eciRevisionHeader = 'ECI REVISION';
  shippingDateHeader = 'SHIPPING DATE';
  trailerNumberHeader = 'TRAILER NUMBER';
  shippingCarrierHeader = 'SHIPPING CARRIER';
  trackAirNbrHeader = 'TRACKING/AIRBILL NUMBER';
  lifeCycleHeader = 'LIFE CYCLE CODE';
  supShpDtChngReqHeader = 'SUPP SHP DT CHG REQ';
  supShpDtChngComHeader = 'SUPP SHP DT CHG CMMNT';
  palletCodeHeader = 'PALLET CODE';

  setExportOrderDetails(
    exportDetails: TrialOrderDetails[],
    currentRole: string,
    displayPOTrackingNumber: string,
    eventId: string
  ) {
    if (currentRole !== 'Supplier' && displayPOTrackingNumber === 'false') {
      this.exportNAMCManifestOrders(exportDetails, eventId);
    } else if (
      currentRole !== 'Supplier' &&
      displayPOTrackingNumber === 'true'
    ) {
      this.exportNAMCPurchaseOrders(exportDetails, eventId);
    } else if (
      currentRole === 'Supplier' &&
      displayPOTrackingNumber === 'false'
    ) {
      this.exportSupplierManifestOrders(exportDetails, eventId);
    } else if (
      currentRole === 'Supplier' &&
      displayPOTrackingNumber === 'true'
    ) {
      this.exportSupplierPurchaseOrders(exportDetails, eventId);
    }
  }

  exportNAMCManifestOrders(
    exportNAMCManifestOrderDetails: TrialOrderDetails[],
    eventId: string
  ) {
    const headers = [
      this.orderStatusHeader,
      this.orderTrackingHeader,
      this.psmsRoutingHeader,
      'KANBAN NO',
      this.orderTypeHeader,
      this.eventNameHeader,
      'PROJECT',
      this.specialistNameHeader,
      this.specialistCodeHeader,
      this.supplierCodeHeader,
      'PART NO',
      'PART NAME',
      'REVISION',
      this.namcDueHeader,
      this.supPickupHeader,
      this.supShipHeader,
      this.supNameHeader,
      'PLANT 1 QTY',
      'PLANT 2 QTY',
      'PLANT 3 QTY',
      'NA PIPE STATUS',
      'TRIAL QTY',
      'ASSYTRAINQTY',
      'CFQTY',
      'PEQTY',
      'QEQTY',
      'AXWQTY',
      'ASSY SAFETY',
      'PLASTICS SAFETY',
      'QDQE',
      'VUTEQ',
      'QTY1',
      'QTY2',
      'QTY3',
      'QTY4',
      'QTY5',
      'TOTAL QTY',
      this.namcComHeader,
      this.storeAddHeader,
      this.lineAddHeader,
      this.supComHeader,
      'DOCK CODE',
      this.manifestNbrHeader,
      this.receiverNbrHeader,
      this.eciNumberHeader,
      this.eciRevisionHeader,
      this.shippingDateHeader,
      this.trailerNumberHeader,
      this.shippingCarrierHeader,
      this.trackAirNbrHeader,
      this.lifeCycleHeader,
      'OWK LABEL LINE1',
      'OWK LABEL LINE2',
      'SCAN COMMENT',
      this.supShpDtChngReqHeader,
      this.supShpDtChngComHeader,
      this.palletCodeHeader,
    ];
    this.exportFinalManifestOrders(
      exportNAMCManifestOrderDetails,
      headers,
      eventId
    );
  }

  exportFinalManifestOrders(
    exportNAMCManifestOrderDetails: TrialOrderDetails[],
    headers: string[],
    eventId: string
  ) {
    const exportData: any[] = [];
    exportNAMCManifestOrderDetails.forEach((e) => {
      const obj = {
        ORDER_STATUS: e.status,
        ORDER_TRACKING_NO: e.orderNumber,
        PSMS_ROUTING: e.psmsRouting,
        KANBAN_NO: e.kanbanNumber,
        ORDER_TYPE: e.orderType,
        EVENT_NAME: e.eventName,
        PROJECT: e.projectCode,
        SPECIALIST_NAME: e.specialistName,
        SPECIALIST_CODE: e.specialistCd,
        SUPPLIER_CODE: e.supplierCd,
        PART_NO: e.partNumber,
        PART_NAME: e.partName,
        REVISION: e.revisionDate,
        NAMC_DUE_DATE: e.orderDueDate,
        SUP_PICKUP_DATE: e.supplierPickupDate,
        SUP_SHIP_DATE: e.promiseDate,
        SUPPLIER_NAME: e.supplierName,
        PLANT_1_QTY: e.plant1Qty,
        PLANT_2_QTY: e.plant2Qty,
        PLANT_3_QTY: e.plant3Qty,
        NA_PIPE_STATUS: e.naPipeStatus,
        TRIAL_QTY: e.TRIALQTY,
        ASSYTRAINQTY: e.ASSYTRAINQTY,
        CFQTY: e.CFQTY,
        PEQTY: e.PEQTY,
        QEQTY: e.QEQTY,
        AXWQTY: e.AXWQTY,
        ASSYSAFETY: e.AssySafety,
        PLASTICSSAFETY: e.PlasticsSafety,
        QDQE: e.QDQE,
        VUTEQ: e.Vuteq,
        QTY1: e.QTY1,
        QTY2: e.QTY2,
        QTY3: e.QTY3,
        QTY4: e.QTY4,
        QTY5: e.QTY5,
        TOTAL_QTY: e.totalQty,
        NAMC_COMMENT: e.namcComment,
        STORES_ADDRESS: e.storeAddress,
        LINESIDE_ADDRESS: e.lineSideAddress,
        SUPPLIER_COMMENT: e.supplierComment,
        DOCK_CODE: e.dockCode,
        MANIFEST_NUMBER: e.manifestNumber,
        RECEIVER_NUMBER: e.orderReceiverNbr,
        ECI_NUMBER: e.eciNumber,
        ECI_REVISION: e.eciRevision,
        SHIPPING_DATE: e.shippingDateTime,
        TRAILER_NUMBER: e.trailerNumber,
        SHIPPING_CARRIER: e.shippingCarrier,
        TRACKING_AIRBILL_NUMBER: e.trackingAirbillNumber,
        LIFE_CYCLE_CODE: e.lifeCycleCode,
        OWK_LABEL_LINE1: e.owkLabelLine1,
        OWK_LABEL_LINE2: e.owkLabelLine2,
        SCAN_COMMENT: e.scanComment,
        SUPP_SHP_DT_CHG_REQ: e.requestForSuppShipDtChng,
        SUPP_SHP_DT_CHG_CMMNT: e.requestForSuppShipcmmntChng,
        PALLET_CODE: e.palletCode,
      };
      exportData.push(obj);
    });
    this.excelService.exportAsExcelFile(
      exportData,
      this.generateFileName(
        exportNAMCManifestOrderDetails[0].projectCode,
        eventId
      ),
      headers
    );
  }

  exportNAMCPurchaseOrders(
    exportNAMCPurchaseOrderDetails: TrialOrderDetails[],
    eventId: string
  ) {
    const headers = [
      this.orderStatusHeader,
      this.orderTrackingHeader,
      this.psmsRoutingHeader,
      'PO TRACKING NO',
      'KANBAN NO',
      this.orderTypeHeader,
      this.eventNameHeader,
      'PROJECT',
      this.specialistNameHeader,
      this.specialistCodeHeader,
      this.supplierCodeHeader,
      'PART NO',
      'PART NAME',
      'REVISION',
      this.namcDueHeader,
      this.supPickupHeader,
      this.supShipHeader,
      this.supNameHeader,
      'PLANT 1 QTY',
      'PLANT 2 QTY',
      'PLANT 3 QTY',
      'TRIAL QTY',
      'ASSYTRAINQTY',
      'CFQTY',
      'PEQTY',
      'QEQTY',
      'AXWQTY',
      'ASSY SAFETY',
      'PLASTICS SAFETY',
      'QDQE',
      'VUTEQ',
      'QTY1',
      'QTY2',
      'QTY3',
      'QTY4',
      'QTY5',
      'TOTAL QTY',
      this.namcComHeader,
      this.storeAddHeader,
      this.lineAddHeader,
      this.supComHeader,
      'DOCK CODE',
      this.manifestNbrHeader,
      this.receiverNbrHeader,
      this.eciNumberHeader,
      this.eciRevisionHeader,
      this.shippingDateHeader,
      this.trailerNumberHeader,
      this.shippingCarrierHeader,
      this.trackAirNbrHeader,
      this.lifeCycleHeader,
      'OWK LABEL LINE1',
      'OWK LABEL LINE2',
      'SCAN COMMENT',
      'SEND TO SAP FLAG',
      'ARIBA PR NUMBER',
      'ARIBA ORDER STATUS',
      'ARIBA ORDER COMMENT',
      this.supShpDtChngReqHeader,
      this.supShpDtChngComHeader,
      'SAP STATUS',
      'SAP SUBMISSION COMMENT',
      'PO LINE NUMBER',
      this.palletCodeHeader,
    ];
    this.exportFinalPurchaseOrders(
      exportNAMCPurchaseOrderDetails,
      headers,
      eventId
    );
  }

  exportFinalPurchaseOrders(
    exportNAMCPurchaseOrderDetails: TrialOrderDetails[],
    headers: string[],
    eventId: string
  ) {
    const exportData: any[] = [];
    exportNAMCPurchaseOrderDetails.forEach((e) => {
      const p = e.sapStatus.split(';');
      const t = p[1].toString();
      const s = p[0].toString();
      const obj = {
        ORDER_STATUS: e.status,
        ORDER_TRACKING_NO: e.orderNumber,
        PSMS_ROUTING: e.psmsRouting,
        PO_TRACKING_NO: e.poTrackingNumber,
        KANBAN_NO: e.kanbanNumber,
        ORDER_TYPE: e.orderType,
        EVENT_NAME: e.eventName,
        PROJECT: e.projectCode,
        SPECIALIST_NAME: e.specialistName,
        SPECIALIST_CODE: e.specialistCd,
        SUPPLIER_CODE: e.supplierCd,
        PART_NO: e.partNumber,
        PART_NAME: e.partName,
        REVISION: e.revisionDate,
        NAMC_DUE_DATE: e.orderDueDate,
        SUP_PICKUP_DATE: e.supplierPickupDate,
        SUP_SHIP_DATE: e.promiseDate,
        SUPPLIER_NAME: e.supplierName,
        PLANT_1_QTY: e.plant1Qty,
        PLANT_2_QTY: e.plant2Qty,
        PLANT_3_QTY: e.plant3Qty,
        TRIAL_QTY: e.TRIALQTY,
        ASSYTRAINQTY: e.ASSYTRAINQTY,
        CFQTY: e.CFQTY,
        PEQTY: e.PEQTY,
        QEQTY: e.QEQTY,
        AXWQTY: e.AXWQTY,
        ASSYSAFETY: e.AssySafety,
        PLASTICSSAFETY: e.PlasticsSafety,
        QDQE: e.QDQE,
        VUTEQ: e.Vuteq,
        QTY1: e.QTY1,
        QTY2: e.QTY2,
        QTY3: e.QTY3,
        QTY4: e.QTY4,
        QTY5: e.QTY5,
        TOTAL_QTY: e.totalQty,
        NAMC_COMMENT: e.namcComment,
        STORES_ADDRESS: e.storeAddress,
        LINESIDE_ADDRESS: e.lineSideAddress,
        SUPPLIER_COMMENT: e.supplierComment,
        DOCK_CODE: e.dockCode,
        MANIFEST_NUMBER: e.manifestNumber,
        RECEIVER_NUMBER: e.orderReceiverNbr,
        ECI_NUMBER: e.eciNumber,
        ECI_REVISION: e.eciRevision,
        SHIPPING_DATE: e.shippingDateTime,
        TRAILER_NUMBER: e.trailerNumber,
        SHIPPING_CARRIER: e.shippingCarrier,
        TRACKING_AIRBILL_NUMBER: e.trackingAirbillNumber,
        LIFE_CYCLE_CODE: e.lifeCycleCode,
        OWK_LABEL_LINE1: e.owkLabelLine1,
        OWK_LABEL_LINE2: e.owkLabelLine2,
        SCAN_COMMENT: e.scanComment,
        SEND_TO_SAP_FLAG: e.sendToSapFlag,
        ARIBA_PR_NUMBER: e.aribaPrNumber,
        ARIBA_ORDER_STATUS: e.aribaOrderStatus,
        ARIBA_ORDER_COMMENT: e.aribaOrderComment,
        SUPP_SHP_DT_CHG_REQ: e.requestForSuppShipDtChng,
        SUPP_SHP_DT_CHG_CMMNT: e.requestForSuppShipcmmntChng,
        SAP_STATUS: s,
        SAP_SUBMISSION_COMMENT: t,
        PO_LINE_NUMBER: e.poLineNo,
        PALLET_CODE: e.palletCode,
      };
      exportData.push(obj);
    });
    this.excelService.exportAsExcelFile(
      exportData,
      this.generateFileName(
        exportNAMCPurchaseOrderDetails[0].projectCode,
        eventId
      ),
      headers
    );
  }

  exportSupplierManifestOrders(
    exportSupplierManifestOrderDetails: TrialOrderDetails[],
    eventId: string
  ) {
    const headers = [
      this.orderStatusHeader,
      this.orderTrackingHeader,
      this.psmsRoutingHeader,
      'KANBAN NO',
      this.orderTypeHeader,
      this.eventNameHeader,
      'PROJECT',
      this.specialistNameHeader,
      this.specialistCodeHeader,
      this.supplierCodeHeader,
      'PART NO',
      'PART NAME',
      'REVISION',
      this.namcDueHeader,
      this.supPickupHeader,
      this.supShipHeader,
      this.supNameHeader,
      'NA PIPE STATUS',
      'TOTAL QTY',
      this.namcComHeader,
      this.storeAddHeader,
      this.lineAddHeader,
      this.supComHeader,
      'DOCK CODE',
      this.manifestNbrHeader,
      this.receiverNbrHeader,
      this.eciNumberHeader,
      this.eciRevisionHeader,
      this.shippingDateHeader,
      this.trailerNumberHeader,
      this.shippingCarrierHeader,
      this.trackAirNbrHeader,
      this.lifeCycleHeader,
      'WORK ORDER NUMBER',
      this.supShpDtChngReqHeader,
      this.supShpDtChngComHeader,
      this.palletCodeHeader,
    ];
    this.exportFinalSupplierManifestOrders(
      exportSupplierManifestOrderDetails,
      headers,
      eventId
    );
  }

  exportFinalSupplierManifestOrders(
    exportSupplierManifestOrderDetails: TrialOrderDetails[],
    headers: string[],
    eventId: string
  ) {
    const exportData: any[] = [];
    exportSupplierManifestOrderDetails.forEach((e) => {
      const obj = {
        ORDER_STATUS: e.status,
        ORDER_TRACKING_NO: e.orderNumber,
        PSMS_ROUTING: e.psmsRouting,
        KANBAN_NO: e.kanbanNumber,
        ORDER_TYPE: e.orderType,
        EVENT_NAME: e.eventName,
        PROJECT: e.projectCode,
        SPECIALIST_NAME: e.specialistName,
        SPECIALIST_CODE: e.specialistCd,
        SUPPLIER_CODE: e.supplierCd,
        PART_NO: e.partNumber,
        PART_NAME: e.partName,
        REVISION: e.revisionDate,
        NAMC_DUE_DATE: e.orderDueDate,
        SUP_PICKUP_DATE: e.supplierPickupDate,
        SUP_SHIP_DATE: e.promiseDate,
        SUPPLIER_NAME: e.supplierName,
        NA_PIPE_STATUS: e.naPipeStatus,
        TOTAL_QTY: e.totalQty,
        NAMC_COMMENT: e.namcComment,
        STORES_ADDRESS: e.storeAddress,
        LINESIDE_ADDRESS: e.lineSideAddress,
        SUPPLIER_COMMENT: e.supplierComment,
        DOCK_CODE: e.dockCode,
        MANIFEST_NUMBER: e.manifestNumber,
        RECEIVER_NUMBER: e.orderReceiverNbr,
        ECI_NUMBER: e.eciNumber,
        ECI_REVISION: e.eciRevision,
        SHIPPING_DATE: e.shippingDateTime,
        TRAILER_NUMBER: e.trailerNumber,
        SHIPPING_CARRIER: e.shippingCarrier,
        TRACKING_AIRBILL_NUMBER: e.trackingAirbillNumber,
        LIFE_CYCLE_CODE: e.lifeCycleCode,
        WORK_ORDER_NUMBER: e.workOrderNumber,
        SUPP_SHP_DT_CHG_REQ: e.requestForSuppShipDtChng,
        SUPP_SHP_DT_CHG_CMMNT: e.requestForSuppShipcmmntChng,
        PALLET_CODE: e.palletCode,
      };
      exportData.push(obj);
    });
    this.excelService.exportAsExcelFile(
      exportData,
      this.generateFileName(
        exportSupplierManifestOrderDetails[0].projectCode,
        eventId
      ),
      headers
    );
  }

  exportSupplierPurchaseOrders(
    exportSupplierPurchaseOrderDetails: TrialOrderDetails[],
    eventId: string
  ) {
    const headers = [
      this.orderStatusHeader,
      this.orderTrackingHeader,
      this.psmsRoutingHeader,
      'PO TRACKING NO',
      'KANBAN NO',
      this.orderTypeHeader,
      this.eventNameHeader,
      'PROJECT',
      this.specialistNameHeader,
      this.specialistCodeHeader,
      this.supplierCodeHeader,
      'PART NO',
      'PART NAME',
      'REVISION',
      this.namcDueHeader,
      this.supPickupHeader,
      this.supShipHeader,
      this.supNameHeader,
      'TOTAL QTY',
      this.namcComHeader,
      this.storeAddHeader,
      this.lineAddHeader,
      this.supComHeader,
      'DOCK CODE',
      this.manifestNbrHeader,
      this.receiverNbrHeader,
      this.eciNumberHeader,
      this.eciRevisionHeader,
      this.shippingDateHeader,
      this.trailerNumberHeader,
      this.shippingCarrierHeader,
      this.trackAirNbrHeader,
      this.lifeCycleHeader,
      'WORK ORDER NUMBER',
      'ARIBA PR NUMBER',
      'ARIBA ORDER STATUS',
      'ARIBA ORDER COMMENT',
      this.supShpDtChngReqHeader,
      this.supShpDtChngComHeader,
      'SAP STATUS',
      'SAP SUBMISSION COMMENT',
      'PO LINE NUMBER',
      this.palletCodeHeader,
    ];
    this.exportFinalSupplierPurchaseOrders(
      exportSupplierPurchaseOrderDetails,
      headers,
      eventId
    );
  }

  exportFinalSupplierPurchaseOrders(
    exportSupplierPurchaseOrderDetails: TrialOrderDetails[],
    headers: string[],
    eventId: string
  ) {
    const exportData: any[] = [];
    exportSupplierPurchaseOrderDetails.forEach((e) => {
      const p = e.sapStatus.split(';');
      const t = p[1].toString();
      const s = p[0].toString();
      const obj = {
        ORDER_STATUS: e.status,
        ORDER_TRACKING_NO: e.orderNumber,
        PSMS_ROUTING: e.psmsRouting,
        PO_TRACKING_NO: e.poTrackingNumber,
        KANBAN_NO: e.kanbanNumber,
        ORDER_TYPE: e.orderType,
        EVENT_NAME: e.eventName,
        PROJECT: e.projectCode,
        SPECIALIST_NAME: e.specialistName,
        SPECIALIST_CODE: e.specialistCd,
        SUPPLIER_CODE: e.supplierCd,
        PART_NO: e.partNumber,
        PART_NAME: e.partName,
        REVISION: e.revisionDate,
        NAMC_DUE_DATE: e.orderDueDate,
        SUP_PICKUP_DATE: e.supplierPickupDate,
        SUP_SHIP_DATE: e.promiseDate,
        SUPPLIER_NAME: e.supplierName,
        TOTAL_QTY: e.totalQty,
        NAMC_COMMENT: e.namcComment,
        STORES_ADDRESS: e.storeAddress,
        LINESIDE_ADDRESS: e.lineSideAddress,
        SUPPLIER_COMMENT: e.supplierComment,
        DOCK_CODE: e.dockCode,
        MANIFEST_NUMBER: e.manifestNumber,
        RECEIVER_NUMBER: e.orderReceiverNbr,
        ECI_NUMBER: e.eciNumber,
        ECI_REVISION: e.eciRevision,
        SHIPPING_DATE: e.shippingDateTime,
        TRAILER_NUMBER: e.trailerNumber,
        SHIPPING_CARRIER: e.shippingCarrier,
        TRACKING_AIRBILL_NUMBER: e.trackingAirbillNumber,
        LIFE_CYCLE_CODE: e.lifeCycleCode,
        WORK_ORDER_NUMBER: e.workOrderNumber,
        ARIBA_PR_NUMBER: e.aribaPrNumber,
        ARIBA_ORDER_STATUS: e.aribaOrderStatus,
        ARIBA_ORDER_COMMENT: e.aribaOrderComment,
        SUPP_SHP_DT_CHG_REQ: e.requestForSuppShipDtChng,
        SUPP_SHP_DT_CHG_CMMNT: e.requestForSuppShipcmmntChng,
        SAP_STATUS: s,
        SAP_SUBMISSION_COMMENT: t,
        PO_LINE_NUMBER: e.poLineNo,
        PALLET_CODE: e.palletCode,
      };
      exportData.push(obj);
    });
    this.excelService.exportAsExcelFile(
      exportData,
      this.generateFileName(
        exportSupplierPurchaseOrderDetails[0].projectCode,
        eventId
      ),
      headers
    );
  }

  getCurrentDate(): Date {
    return new Date();
  }

  generateFileName(projectCode: string, eventId: string) {
    const now = this.getCurrentDate();
    const day = String(now.getDate()).padStart(2, '0');
    const month = String(now.getMonth() + 1).padStart(2, '0');
    const year = now.getFullYear();
    const formattedDate = `${month}${day}${year}`;
    const hours = String((now.getHours() + 24) % 12 || 12).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    const seconds = String(now.getSeconds()).padStart(2, '0');
    const formattedTime = `${hours}${minutes}${seconds}`;
    if (eventId !== '0') {
      return `OrdersExport_${projectCode}_${formattedDate}_${formattedTime}`;
    } else {
      return `OrdersExport_ALL_Events_${formattedDate}_${formattedTime}`;
    }
  }
}
