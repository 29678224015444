import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { finalize } from 'rxjs';
import { SelectedRole } from 'src/app/core/model/role-check.model';
import { HomeService } from 'src/app/services/home.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  currentRole: string | undefined;
  selectedRole: SelectedRole = {
    role: undefined,
    namc: undefined,
    supplierCode: undefined,
    childSupplierCode: undefined,
  };
  currentSupplier: string | undefined;
  msgs: string[] = [];
  supplierList: { supplierCode: string; supplierName: string }[] = [];
  selectedSupplierCode: string | undefined;
  selectedSupplierName: string | undefined;
  filteredValue!: string;
  selectedFilter = 'select';

  constructor(
    public dialog: MatDialog,
    private readonly router: Router,
    private readonly ngxUiLoaderService: NgxUiLoaderService,
    private readonly userService: UserService,
    private readonly homeService: HomeService
  ) {}
  userId: string | undefined;
  errorMsg!: string | undefined;
  successMsg!: string | undefined;
  ordersCount:
    | {
        [key: string]: string;
      }
    | undefined = {};

  ngOnInit(): void {
    this.selectedRole = JSON.parse(
      sessionStorage.getItem('roleDetails') ?? '{}'
    );
    this.currentRole = this.selectedRole.role;
    this.currentSupplier = this.selectedRole.supplierCode;
    this.currentRole === 'namc' && this.getSuppliersList();
    this.getMessages();
    this.userService.userDetails.subscribe((user) => {
      this.userId = user?.userId;
      if (this.userId && this.router.url === '/home') { this.getCountOfOrders(); }
    });
  }

  getMessages() {
    this.ngxUiLoaderService.start('Loader-01');
    this.homeService
      .getMessages()
      ?.pipe(finalize(() => this.ngxUiLoaderService.stop('Loader-01')))
      ?.subscribe({
        next: (res) => {
          this.msgs = res.map((e) => e.msgText);
        },
        error: () => {
          this.setErrorMessage(
            'Something Went Wrong While Loading messages List, Please contact System Administrator'
          );
        },
      });
  }

  getCountOfOrders() {
    const payload = {
      criteriaColumn:
        this.selectedFilter !== 'select' ? this.selectedFilter : '',
      criteriaValue: this.filteredValue,
      supplierCode:
        this.currentRole === 'supplier'
          ? this.currentSupplier
          : this.selectedSupplierCode,
      userId: this.userId,
    };
    this.ngxUiLoaderService.start('Loader-02');
    this.homeService
      .getCountOfOrders(payload)
      ?.pipe(finalize(() => this.ngxUiLoaderService.stop('Loader-02')))
      ?.subscribe({
        next: (res) => {
          this.ordersCount = res.reduce(
            (
              acc: {
                [key: string]: string;
              },
              item: {
                attrKey: string;
                attrVal: string;
              }
            ) => {
              acc[item.attrKey] = item.attrVal;
              return acc;
            },
            {}
          );
        },
        error: () => {
          this.setErrorMessage(
            'Something Went Wrong While Loading Order Count List, Please contact System Administrator'
          );
        },
      });
  }

  openAddNewMessage() {
    this.router.navigate(['/new-message']);
  }

  getSuppliersList() {
    this.ngxUiLoaderService.start('Loader-01');
    this.homeService
      .getSuppliersList()
      ?.pipe(finalize(() => this.ngxUiLoaderService.stop('Loader-01')))
      ?.subscribe({
        next: (
          supplierList: { supplierCode: string; supplierName: string }[]
        ) => {
          this.supplierList = supplierList;
        },
        error: () => {
          this.setErrorMessage(
            'Something Went Wrong While Loading Supplier List, Please contact System Administrator'
          );
        },
      });
  }

  setErrorMessage(errorMsg: string) {
    this.errorMsg = errorMsg;
    setTimeout(() => {
      this.errorMsg = undefined;
    }, 5000);
  }

  supplierCodeChange() {
    this.selectedSupplierName = this.supplierList.find(
      (element) => element.supplierCode === this.selectedSupplierCode
    )?.supplierName;
  }

  supplierNameChange() {
    this.selectedSupplierCode = this.supplierList.find(
      (element) => element.supplierName === this.selectedSupplierName
    )?.supplierCode;
  }

  resetFilters() {
    this.filteredValue = '';
    this.selectedFilter = 'select';
    this.getCountOfOrders();
  }

  navigateOrderDetails(orderDetails: string){
    if(this.currentRole === 'supplier'){
      this.router.navigate(['/home/quicklinkordersupplier'], {
        state: { myData: sessionStorage.setItem('eventId','-1'), oderDetails: sessionStorage.setItem('quickLinkOrderDetails',orderDetails) },
      });
    }
    else{
      this.router.navigate(['/home/quicklinkordernamc'], {
        state: { myData: sessionStorage.setItem('eventId','-1'), oderDetails: sessionStorage.setItem('quickLinkOrderDetails',orderDetails) },
      });
    }
  }

  navigateToPerformance(){
    this.router.navigate(['/performance'],);
  }
}
