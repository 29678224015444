<app-notification-message
  [errorMessage]="errorMessage"
  [successMessage]="successMessage"
>
</app-notification-message>
<div class="upperText">
  Primary Supplier Contact Information
</div>
<div class="upperText">
  Please Select Supplier Code From Dropdown or Enter Custom Supplier Code To
  Search
</div>
<div class="container">
  <div class="tile">
    <form [formGroup]="myForm">
      <div class="input-container">
        <div class="label-container">
          <div class="vertical-bar"></div>
          <label for="supplierCode">SUPPLIER CODE</label>
        </div>
        <p-dropdown
          formControlName="supplierCode"
          [options]="supplierCodeList"
          optionLabel="supplierCode"
          optionValue="supplierCode"
          class="dropdownOptions"
          placeholder="(Select)"
          [virtualScroll]="true"
          scrollHeight="200px"
          [virtualScrollItemSize]="30"
          (onChange)="supplierCodeChange()"
          [filter]="true"
        >
        </p-dropdown>
        <div
          *ngIf="
            myForm.get('supplierCode')?.touched &&
            myForm.get('supplierCode')?.invalid &&
            !myForm.value.customSupplierCode
          "
        >
          <small
            *ngIf="myForm.get('supplierCode')?.errors?.['required']"
            class="errorValid"
            >Supplier Code is required.</small
          >
        </div>
      </div>
      <div class="input-container">
        <div class="label-container">
          <div class="vertical-bar"></div>
          <label for="supplierName">SUPPLIER NAME</label>
        </div>
        <p-dropdown
          formControlName="supplierName"
          [options]="supplierCodeList"
          [virtualScrollItemSize]="30"
          optionLabel="supplierName"
          optionValue="supplierName"
          class="dropdownOptions"
          placeholder="(Select)"
          [virtualScroll]="true"
          scrollHeight="200px"
          (onChange)="supplierNameChange()"
          [filter]="true"
        >
        </p-dropdown>
        <div
          *ngIf="
            myForm.get('supplierName')?.touched &&
            myForm.get('supplierName')?.invalid &&
            !myForm.value.customSupplierCode
          "
        >
          <small
            *ngIf="myForm.get('supplierName')?.errors?.['required']"
            class="errorValid"
            >Supplier Name is required.</small
          >
        </div>
      </div>
      <div class="input-container">
        <label for="customSupplierCode">CUSTOM SUPPLIER CODE</label>
        <input
          formControlName="customSupplierCode"
          type="text"
          id="customSupplierCode"
          maxlength="5"
          name="customSupplierCode"
          (change)="customSupplierCodeChange()"
          disableSpecialCharacters
          autocomplete="off"
        />
        <button class="b2 add" (click)="onSubmit()">SUBMIT</button>
      </div>
    </form>
  </div>
</div>
<!-- table.component.html -->
<div *ngFor="let supplierCode of contactList; let j = index" style="margin-bottom: 4rem">
  <div class="tile1" *ngIf="hideDetails">
    <div class="caption">
      {{ supplierName }} ( {{ supplierCode }} ) CONTACT INFORMATION
    </div>
    <div>
      <div
        *ngFor="let contact of contactDetails[j]; let i = index"
        [ngClass]="{ 'even-tile': i % 2 === 0, 'odd-tile': i % 2 !== 0 }"
      >
        <div class="tile1-content">
          <div class="row">
            <div class="col">
              <span class="label">FIRST NAME:</span>
              <span class="detail">{{
                contact.firstname === "null" ? "" : contact.firstname
              }}</span>
            </div>
            <div class="col">
              <span class="label">CONTACT ID:</span>
              <span class="detail">{{
                contact.suprole === "null" ? "" : contact.suprole
              }}</span>
            </div>
            <div class="col">
              <span class="label">ADDRESS:</span>
              <span class="detail">{{
                contact.address === "null" ? "" : contact.address
              }}</span>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <span class="label">LAST NAME:</span>
              <span class="detail">{{
                contact.lastname === "null" ? "" : contact.lastname
              }}</span>
            </div>
            <div class="col">
              <span class="label">PHONE:</span>
              <span class="detail">{{
                contact.phone === "null" ? "" : contact.phone
              }}</span>
            </div>
            <div class="col">
              <span class="label">CITY:</span>
              <span class="detail">{{
                contact.city === "null" ? "" : contact.city
              }}</span>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <span class="label">TITLE:</span>
              <span class="detail">{{
                contact.title === "null" ? "" : contact.title
              }}</span>
            </div>
            <div class="col">
              <span class="label">EMAIL:</span>
              <a [href]="'mailto:' + contact.email">
                <span class="detailEmail">{{
                  contact.email === "null" ? "" : contact.email
                }}</span>
              </a>
            </div>
            <div class="col">
              <span class="label">STATE - COUNTRY:</span>
              <span class="detail"
                >{{ contact.state === "null" ? "" : contact.state + " -" }}
                {{ contact.country === "null" ? "" : contact.country }}</span
              >
            </div>
          </div>
          <div class="row">
            <div class="col">
              <span class="label">CONTACT DEF:</span>
              <span class="detail">{{
                contact.definitionId === "null" ? "" : contact.definitionId
              }}</span>
            </div>
            <div class="col">
              <span class="label"></span>
              <span class="detail"></span>
            </div>
            <div class="col" *ngIf="contact?.zipcode">
              <span class="label">ZIP:</span>
              <span class="detail">{{
                contact.zipcode === "null" ? "" : contact.zipcode
              }}</span>
            </div>
          </div>
        </div>
        <hr class="styled-hr" />
      </div>
    </div>
  </div>
</div>
<footer class="footer">
  <button class="cancel-button ms-2" (click)="cancel()">CANCEL</button>
</footer>
