import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { User } from 'src/app/core/model/user.model';
import { UploadOrderSupplierService } from 'src/app/services/upload-orders-supplier/upload-orders-supplier.service';
import { UserService } from 'src/app/services/user.service';
import axios from 'axios';
import { Event } from 'src/app/core/model/events.model';
@Component({
  selector: 'app-upload-orders-data',
  templateUrl: './upload-orders-data.component.html',
  styleUrls: ['./upload-orders-data.component.scss']
})
export class UploadOrdersDataComponent implements OnInit {
  eventForm!: FormGroup;
  uploadedFileName: string | null = null;
  events: Event[] = [];
  hideForm = false;
  errorMessage: any = null;
  successMessage: any = null;
  user: User | undefined;
  userId = '';
  eventid = 0;
  selectedOption = '';
  uploadUrlErrorMessage= 'Failed to generate upload URL.';
  constructor(
    private readonly fb: FormBuilder,
    private readonly ngxUiLoaderService: NgxUiLoaderService,
    private readonly uploadOrdersService: UploadOrderSupplierService,
    private readonly userService: UserService
  ) { }

  ngOnInit(): void {
    this.userService.userDetails.subscribe((value) => {
      if (value !== null) {
        this.user = value;
        this.userId = value.userId
      }
    });
    this.eventForm = this.fb.group({
      event: ['', Validators.required],
      eventDescription: [{ value: '', disabled: true }],
      eventType: [{ value: '', disabled: true }],
      projectCode: [{ value: '', disabled: true }],
      file: [null, Validators.required],
    });
    this.eventForm.get('eventDescription')?.disable();
    this.eventForm.get('eventType')?.disable();
    this.eventForm.get('projectCode')?.disable();
    this.summonEvents();
  }

  summonEvents() {
    this.uploadOrdersService.summonEvents().subscribe({
      next: (response) => {
        this.events = response;
      },
      error: (_error) => {
        this.errorMessage = 'Failed to fetch events. Please try again later.';
      },
    });
  }
  setDetails(event: any): void {
    const selectedEventId = (event.target as HTMLSelectElement).value;
    const selectedEvent = this.events.find(
      (event: { eventId: number; }) => event.eventId === +selectedEventId

    );
    if (selectedEvent) {
      this.eventid = selectedEvent.eventId

      this.eventForm.patchValue({
        eventDescription: selectedEvent.eventDesc,
        eventType: selectedEvent.eventType,
        projectCode: selectedEvent.projectCode,
        eventid: selectedEvent.eventId
      });
      this.eventForm.get('eventDescription')?.disable();
      this.eventForm.get('eventType')?.disable();
      this.eventForm.get('projectCode')?.disable();
    }
  }

  onFileChange(event: any) {
    const file = event.target.files[0];
    if (file) {
      this.uploadedFileName = file.name;
      this.eventForm.patchValue({
        file: file,
      });
    }
  }

  removeFile() {
    this.uploadedFileName = null;
    this.eventForm.patchValue({
      file: null,
    });
  }

  showForm(_event: any): void {
    this.eventForm.reset();
    this.hideForm = true;
    this.uploadedFileName = null;
    this.eventForm.get('eventDescription')?.disable();
    this.eventForm.get('eventType')?.disable();
    this.eventForm.get('projectCode')?.disable();
  }
  options = [
    { value: '1', name: 'Upload ECI Data' },
    { value: '2', name: 'Upload Label Request' },
    { value: '3', name: 'Upload Shipping Info' }
  ];
  buttonTextMapping: any = {
    '1': 'Upload ECI Data',
    '2': 'Upload Label Request',
    '3': 'Upload Orders File'
  };
  
  get buttonText(): string {
    return this.buttonTextMapping[this.selectedOption] || 'Default Text';
  }
  onSubmit(): void {
    if (this.eventForm?.valid && this.uploadedFileName) {
      this.ngxUiLoaderService.start(); 
      const folderMapping: { [key: string]: string } = {
        '1': 'uploadeci',
        '2': 'uploadlabel',
        '3': 'uploadshipping',
      };
      const folderName = folderMapping[this.selectedOption];
      const file = this.eventForm.get('file')?.value;
      if (folderName && file) {
        const originalFileName = file.name;
        const fileType = originalFileName.substring(originalFileName.lastIndexOf('.') + 1);
        const fileName = `${folderName}/${originalFileName}`;
        this.uploadOrdersService.uploadFile(fileName, fileType).subscribe({
          next: (response) => {
            axios.put(response.url, fileName, {
              headers: {
                'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
              },
            });
            const payload = {
              eventid: this.eventid,
              userid: this.userId,
              filename: fileName,
            };
            this.successMessage = 'File uploaded successfully!';
            this.apiCall(payload);
          },
          error: (_err) => {
            this.errorMessage = this.uploadUrlErrorMessage;
          },
        });
      }
    } else {
      this.eventForm.markAllAsTouched();
    }
  }
  apiCall(payload: any) {
    switch (this.selectedOption) {
      case '1': 
        this.uploadOrdersService.callEciApi(payload).subscribe({
          next: (_response: any) => {
            //No work
          },
          error: (_err: any) => {
            this.errorMessage = this.uploadUrlErrorMessage;
          },
        });
        break;
      case '2':
        this.uploadOrdersService.callLabelApi(payload).subscribe({
          next: (_response: any) => {
            //No work
          },
          error: (_err: any) => {
            this.errorMessage = this.uploadUrlErrorMessage;
          },
        });
        break;
      case '3': 
        this.uploadOrdersService.callShippingApi(payload).subscribe({
          next: (_response: any) => {
            //No work
          },
          error: (_err: any) => {
            this.errorMessage = this.uploadUrlErrorMessage;
          },
        });
        break;
    }
  }
}