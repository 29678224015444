
<div class="popup-container">
    <button class="close black-button" (click)="closeDialog()">
      <mat-icon>close</mat-icon>
    </button>
  
    <h2 class="p-3 mb-0 roboto-medium">Warning</h2>
    <hr id="shadow" />
    <br />
    <div class="dialog-body">
      <p>Are you sure you want to leave the page without saving?</p>
      <p>Click "NO"� to go back and submit</p>
    </div>
    <div  class="dialog-actions">
      <button mat-button class="yes-button" (click)="onYesClick()">YES</button>
      <button mat-button class="no-button" (click)="closeDialog()">NO</button>
    </div>
  </div>