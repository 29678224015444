<div class="popup-container">
  <button class="close black-button" (click)="closeDialog()">
    <mat-icon>close</mat-icon>
  </button>

  <h2 class="p-3 mb-0 roboto-medium">NAMC Comments</h2>
  <hr id="shadow" />
  <br />
  <app-notification-message
    [errorMessage]="errorMessage"
    [successMessage]="successMessage"
  >
  </app-notification-message>
  <div>
    <p class="text-center paragraph">
      Are you sure you want to update NAMC Comment for the selected order?
    </p>
    <textarea
      class="ms-2"
      rows="5"
      cols="80"
      maxlength="1000"
      [(ngModel)]="namcComment"
      (ngModelChange)="onCommentChange($event)"
      disableSpecialCharacters
    >
    </textarea>
  </div>
  <div class="right-align mt-2">
    <button
      (click)="updateNamcComments()"
      [disabled]="commentLengthCheck"
      class="b2-button add roboto-medium"
    >
      SAVE
    </button>
    <button (click)="closeDialog()" class="b2 me-3 ms-3 roboto-bold">
      CANCEL
    </button>
  </div>
</div>
