<app-notification-message
  [errorMessage]="errorMessage"
  [successMessage]="successMessage"
></app-notification-message>
<div class="export-order ms-3 mt-3">
  <button (click)="exportOrderShipping()">
    <fa-icon [icon]="faShareFromSquare" title="Export Orders"></fa-icon>
  </button>
</div>
<div class="mt-3 pb-5">
  <div class="d-flex me-3 ms-3 mb-3">
    <div class="field-container d-flex flex-column">
      <label for="input1" class="form-label">EVENT NAME</label>
      <input
        type="text"
        id="input1"
        class="form-control"
        [(ngModel)]="formData.eventName"
        disabled
      />
    </div>
    <div class="field-container d-flex flex-column">
      <label for="input2" class="form-label">NAMC CODE</label>
      <input
        type="text"
        id="input2"
        class="form-control"
        [(ngModel)]="formData.namcCode"
        disabled
      />
    </div>
    <div class="field-container d-flex flex-column">
      <label for="input3" class="form-label">SHIPMENT LOCATION</label>
      <input
        type="text"
        id="input3"
        class="form-control"
        [(ngModel)]="formData.shipmentLocation"
        disabled
      />
    </div>
  </div>
  <div class="d-flex me-3 ms-3 mb-3">
    <div class="field-container d-flex flex-column">
      <label for="input1" class="form-label">SHIPMENT ADDRESS</label>
      <input
        type="text"
        id="input1"
        class="form-control"
        [(ngModel)]="formData.shipmentAddress"
        disabled
      />
    </div>
    <div class="field-container d-flex flex-column">
      <label for="input2" class="form-label">SHIPMENT CITY</label>
      <input
        type="text"
        id="input2"
        class="form-control"
        [(ngModel)]="formData.shipmentCity"
        disabled
      />
    </div>
    <div class="field-container d-flex flex-column">
      <label for="input3" class="form-label">SHIPMENT STATE/PROVINCE</label>
      <input
        type="text"
        id="input3"
        class="form-control"
        [(ngModel)]="formData.shipmentState"
        disabled
      />
    </div>
  </div>
  <div class="d-flex me-3 ms-3 mb-3">
    <div class="field-container d-flex flex-column">
      <label for="input1" class="form-label">SHIPMENT COUNTRY</label>
      <input
        type="text"
        id="input1"
        class="form-control"
        [(ngModel)]="formData.shipmentCountry"
        disabled
      />
    </div>
    <div class="field-container d-flex flex-column">
      <label for="input2" class="form-label">SHIPMENT ZIP</label>
      <input
        type="text"
        id="input2"
        class="form-control"
        [(ngModel)]="formData.shipmentPostalCode"
        disabled
      />
    </div>
    <div class="field-container d-flex flex-column">
      <label for="input3" class="form-label">DOCK CODE</label>
      <input
        type="text"
        id="input3"
        class="form-control"
        [(ngModel)]="formData.dockCode"
        disabled
      />
    </div>
  </div>
  <div class="d-flex me-3 ms-3 mb-3">
    <div class="field-container d-flex flex-column">
      <label for="input1" class="form-label">LOGISTIC PROVIDER</label>
      <input
        type="text"
        id="input1"
        class="form-control"
        [(ngModel)]="formData.logisticsProvider"
        disabled
      />
    </div>
    <div class="field-container d-flex flex-column">
      <label for="input2" class="form-label">PACKAGING NEED</label>
      <input
        type="text"
        id="input2"
        class="form-control"
        [(ngModel)]="formData.packagingNeed"
        disabled
      />
    </div>
    <div class="field-container d-flex flex-column">
      <label for="input3" class="form-label">ORDER TYPE</label>
      <input
        type="text"
        id="input3"
        class="form-control"
        [(ngModel)]="formData.orderType"
        disabled
      />
    </div>
  </div>
</div>
<div class="create_footer fixed-bottom">
  <button
    class="cancel me-3 mt-2 roboto-bold cancelbtnalign"
    (click)="cancel()"
  >
    BACK
  </button>
</div>
