import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { faShareFromSquare } from '@fortawesome/free-solid-svg-icons';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { TrialOrderShippingAdd } from 'src/app/core/model/trial-order.model';
import { TrialOrdersService } from 'src/app/services/event-and-orders/trial-orders.service';
import { ExcelService } from 'src/app/services/excel.service';

@Component({
  selector: 'app-display-order-shipping-address',
  templateUrl: './display-order-shipping-address.component.html',
  styleUrls: ['./display-order-shipping-address.component.scss'],
})
export class DisplayOrderShippingAddressComponent implements OnInit {
  faShareFromSquare = faShareFromSquare;
  formData: TrialOrderShippingAdd = {
    projectCode: '',
    eventName: '',
    namcCode: '',
    shipmentLocation: '',
    shipmentAddress: '',
    shipmentCity: '',
    shipmentState: '',
    shipmentCountry: '',
    shipmentPostalCode: '',
    dockCode: '',
    logisticsProvider: '',
    packagingNeed: '',
    orderType: '',
  };
  successMessage: string | undefined = undefined;
  errorMessage: string | undefined = undefined;
  eventId = '';

  constructor(
    private readonly ngxUiLoaderService: NgxUiLoaderService,
    private readonly trialOrderervice: TrialOrdersService,
    private readonly excelService: ExcelService
  ) {}

  ngOnInit(): void {
    this.getOrderShippingData();
  }

  getOrderShippingData() {
    this.eventId = String(sessionStorage.getItem('eventId'));
    this.ngxUiLoaderService.start('shippingdetails');
    this.trialOrderervice.getOrderShipping(this.eventId)?.subscribe({
      next: (result) => {
        if (result && result.length > 0) {
          this.ngxUiLoaderService.stop('shippingdetails');
          this.formData = result[0];
        } else {
          this.ngxUiLoaderService.stop('shippingdetails');
          this.errorMessage = 'There is no data.';
        }
      },
      error: (err) => {
        this.ngxUiLoaderService.stop('shippingdetails');
        const error = err as HttpErrorResponse;
        this.errorMessage = error.error.error;
        setTimeout(() => {
          this.errorMessage = undefined;
          this.successMessage = undefined;
        }, 5000);
      },
    });
  }

  exportOrderShipping() {
    const headers = [
      'EVENT NAME',
      'NAMC CODE',
      'SHIPMENT LOCATION',
      'SHIPMENT ADDRESS',
      'SHIPMENT CITY',
      'SHIPMENT STATE/PROVINCE',
      'SHIPMENT COUNTRY',
      'SHIPMENT ZIP',
      'DOCK CODE',
      'LOGISTICS PROVIDER',
      'PACKAGING NEED',
      'ORDER TYPE',
    ];
    const exportData: TrialOrderShippingAdd[] = [];
    const obj = {
      eventName: this.formData?.eventName,
      namcCode: this.formData.namcCode,
      shipmentLocation: this.formData.shipmentLocation,
      shipmentAddress: this.formData.shipmentAddress,
      shipmentCity: this.formData.shipmentCity,
      shipmentState: this.formData.shipmentState,
      shipmentCountry: this.formData.shipmentCountry,
      shipmentPostalCode: this.formData.shipmentPostalCode,
      dockCode: this.formData.dockCode,
      logisticsProvider: this.formData.logisticsProvider,
      packagingNeed: this.formData.packagingNeed,
      orderType: this.formData.orderType,
    };
    exportData.push(obj);
    try {
      this.excelService.exportAsExcelFile(
        exportData,
        this.generateFileName(this.formData.projectCode as string),
        headers
      );
    } catch (error) {
      if (error instanceof Error) {
        this.errorMessage = error.message;
      } else {
        this.errorMessage = 'Something went wrong while exporting file.';
      }
    }
  }

  generateFileName(projectCode: string) {
    if (!projectCode) {
      throw new Error(
        'Project code is probably empty or null to generate file.'
      );
    }
    const now = new Date();
    const day = String(now.getDate()).padStart(2, '0');
    const month = String(now.getMonth() + 1).padStart(2, '0');
    const year = now.getFullYear();
    const formattedDate = `${month}${day}${year}`;
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    const seconds = String(now.getSeconds()).padStart(2, '0');
    const formattedTime = `${hours}${minutes}${seconds}`;
    return `OrderShippingAddressExport_${projectCode}_${formattedDate}_${formattedTime}`;
  }

  cancel() {
    window.history.back();
  }
}
