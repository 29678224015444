import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError } from 'rxjs';
import { handleError } from 'src/app/core/common/common';
import { Event } from 'src/app/core/model/events.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class UploadOrderSupplierService {
  baseApiUrl: string;
  constructor(private readonly http: HttpClient) {
    this.baseApiUrl = environment.baseAPIURL;
  }
  summonEvents() {
    return this.http
      .get<Event[]>(`${this.baseApiUrl}/uploadEci`, {
        withCredentials: true,
        responseType: 'json',
      })
      .pipe(catchError(handleError));
  }
  uploadFile(fileName: string, fileType: string): Observable<any> {
    return this.http
      .get<any>(`${environment.baseAPIURL}/uploadfile?fileName=${fileName}&fileType=${fileType}`, {
        withCredentials: true,
        responseType: 'json',
      })
      .pipe(catchError(handleError)); 
    
  }
  callEciApi(payload: any): Observable<any> {
    return this.http.post<any>(`${environment.baseAPIURL}/event-orders/orders/eci`, payload,{
      withCredentials: true,
      responseType: 'json',
    })
    .pipe(catchError(handleError));
  }
  callLabelApi(payload: any): Observable<any> {
    return this.http.post<any>(`${environment.baseAPIURL}/event-orders/orders/label`, payload,{
      withCredentials: true,
      responseType: 'json',
    })
    .pipe(catchError(handleError));
  }
  callShippingApi(payload: any): Observable<any> {
    return this.http.post<any>(`${environment.baseAPIURL}/event-orders/orders/shipping`, payload,{
      withCredentials: true,
      responseType: 'json',
    })
    .pipe(catchError(handleError));
  }
}
