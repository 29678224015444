import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
@Component({
  selector: 'app-cancel-warning',
  templateUrl: './cancel-warning.component.html',
  styleUrls: ['./cancel-warning.component.scss']
})
export class CancelWarningComponent {
 
  constructor(
    private readonly dialogRef: MatDialogRef<CancelWarningComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
  }

 onYesClick(): void {
    // Add your 'Yes' logic here
    this.dialogRef.close('yes');
  }



  closeDialog(): void {
    this.dialogRef.close();
  }

}
