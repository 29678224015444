import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { PerformanceService } from 'src/app/services/suppliers/performance.service';
import { CreateTrailsService } from 'src/app/services/event-and-orders/create-trails.service';
import { DatePipe } from '@angular/common';
import { AppendOrderModel, Data } from 'src/app/core/model/trial-order.model';
import { SupplierListMap } from 'src/app/core/model/performance.model';
@Component({
  selector: 'app-view-edit',
  templateUrl: './view-edit.component.html',
  styleUrls: ['./view-edit.component.scss'],
})
export class ViewEditComponent implements OnInit {
  data: Data = {} as Data;
  ordernumber: string | undefined;
  trackingnumber = ''
  psmsRouting = ''
  supplierCodes: SupplierListMap[] = [];
  suppliercode = ''
  suppliername = ''
  comment = ''
  kanbanNumber = ''
  partNumber = ''
  partName = ''
  plant1Qty = ''
  plant2Qty = ''
  plant3Qty = ''
  totalQuantity = ''
  storeAddress = ''
  lineSideAdress = ''
  specialistName = ''
  specialistCode = '';
  namccomment = ''
  supplierComment = ''
  scanComment = ''
  namcDate = ''
  disableSave = false;
  isSaveDisabled = false;
  supplierPickupDate = ''
  shippingDateTime = ''
  manifestNumber = ''
  deliveryduedate = ''
  lifeCycleCode = ''
  sendToSapFlag = ''
  poLineNo = ''
  aribaPrNumber = ''
  aribaOrderStatus = ''
  aribaOrderComment = ''
  palletCode = ''
  mandatoryFieldError = 'This field is mandatory';
  constructor(private readonly router: Router, private readonly datePipe: DatePipe,
    private readonly CreateTrailsService: CreateTrailsService, private readonly performanceService: PerformanceService, private readonly route: ActivatedRoute, private readonly dialog: MatDialog,) { }
  orderQuantities = [
    { name: 'TRIALQTY', quantity: 0 },
    { name: 'ASSYTRAINQTY', quantity: 0 },
    { name: 'CFQTY', quantity: 0 },
    { name: 'PEQTY', quantity: 0 },
    { name: 'QEQTY', quantity: 0 },
    { name: 'AXWQTY', quantity: 0 }
  ];
  isViewMode = false;
  editMode = false
  response: AppendOrderModel = {} as AppendOrderModel;
  defaultQuantities: string[] = [];
  errorMessage = '';
  mandatoryFieldErrors = {
    partNumber: '',
    partName: '',
    supplierCode: '',
    totalQuantity: ''
  }
  dateErrors = {
    supplierDeliveryDate: '',
    supplierPickupDate: ''
  };
  totalErrorMessage = ''
  validateQuantities(): boolean {
    let isValid = true;
    const hasValidQuantity = this.orderQuantities.some((item) => item.quantity > 0);

    if (!hasValidQuantity) {
      this.errorMessage = 'At least 1 Quantity Name is Required';
      isValid = false;
    } else {
      this.errorMessage = '';
    }

    this.mandatoryFieldErrors.partNumber = this.partNumber.trim() ? '' : this.mandatoryFieldError;
    this.mandatoryFieldErrors.partName = this.partName.trim() ? '' : this.mandatoryFieldError;
    this.mandatoryFieldErrors.supplierCode = this.suppliercode.trim() ? '' : this.mandatoryFieldError;
    this.mandatoryFieldErrors.totalQuantity = this.totalQuantity.trim() ? '' : this.mandatoryFieldError;
    if (
      !this.partNumber.trim() ||
      !this.partName.trim() ||
      !this.suppliercode.trim() ||
      !this.totalQuantity?.trim()
    ) {
      isValid = false;
    }

    if (Number(this.totalQuantity) !== this.calculateTotalQuantity()) {
      this.totalErrorMessage = 'Total Qty must match the sum of all Quantity Names values';
      isValid = false;
    } else {
      this.totalErrorMessage = '';
    }

    return isValid;
  }
  calculateTotalQuantity(): number {
    return this.orderQuantities.reduce((sum, item) => sum + Number(item.quantity || 0), 0);
  }
  isInvalidFutureDate(_date: string) {
    this.dateErrors = this.dateErrors || {};
    let isValid = true;
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);
    const supplierPickupDate = new Date(this.supplierPickupDate);
    const orderDeliveryDate = new Date(this.deliveryduedate);
    if (supplierPickupDate <= currentDate) {
      this.dateErrors.supplierPickupDate =
        'Selected date is invalid (it can not be present or past date)';
      isValid = false;
    } else {
      this.dateErrors.supplierPickupDate = '';
    }
    if (orderDeliveryDate <= currentDate) {
      this.dateErrors.supplierDeliveryDate =
        'Selected date is invalid (it can not be present or past date)';
      isValid = false
    } else {
      this.dateErrors.supplierDeliveryDate = '';
    }
    return isValid;
  }
  ngOnInit(): void {
    const navigationState = history?.state;
    this.data = JSON.parse(navigationState.data);
    this.CreateTrailsService.getQuantityNames(this.data.eventId).subscribe((res) => {
      this.response = res
      this.defaultQuantities = res.trialEventQuantityNameLst.map((element) => {
        return element.qNameBox;
      });
    });
    if (navigationState.mode === 'view') {
      this.isViewMode = true;
      this.mapData();
    } else if (navigationState.mode === 'edit') {
      this.isViewMode = false;
      this.editMode = true;
      this.fetchSupplierCodes();
      this.checkDisableSave();
      this.mapData();
    }
  }
  checkDisableSave(): void {
    if (!this.disableSave) {
      if (this.data.status === 'Completed' || this.data.status === 'Firmed' || this.data.status === 'Cancelled' || this.data.status === 'Awaiting_Labels' || this.data.status === 'Manifest_Created') {
        this.disableSave = true;
      }
      else {
        this.disableSave = false;
      }
    }
    if (this.data.manifestNumber && this.data.manifestNumber.trim() !== '') {
      this.disableSave = true;
    }
    this.isSaveDisabled = this.disableSave;
  }

  fetchSupplierCodes(): void {
    this.performanceService.suppliersDropDown()?.subscribe({
      next: (res) => {
        this.supplierCodes = res;
      },
      error: (_error) => {
        this.errorMessage = 'Error fetching supplier codes';
      },
    });
  }
  onSupplierCodeChange(selectedCode: string): void {
    const selectedSupplier = this.supplierCodes.find(
      (supplier) => supplier.supplierCode === selectedCode
    );
    this.suppliername = selectedSupplier ? selectedSupplier.supplierName : '';
  }
  mapData() {
    this.trackingnumber = this.data?.poTrackingNumber
    this.ordernumber = this.data?.orderNumber
    this.psmsRouting = this.data?.psmsRouting
    this.suppliercode = this.data?.supplierCd
    this.suppliername = this.data?.supplierName
    this.kanbanNumber = this.data?.kanbanNumber
    this.partNumber = this.data?.partNumber
    this.partName = this.data?.partName
    this.plant1Qty = this.data?.plant1Qty
    this.plant2Qty = this.data?.plant2Qty
    this.plant3Qty = this.data?.plant3Qty
    this.totalQuantity = this.data?.totalQty
    this.lineSideAdress = this.data?.lineSideAddress
    this.storeAddress = this.data?.storeAddress
    this.specialistName = this.data?.specialistName
    this.specialistCode = this.data?.specialistCd
    this.namccomment = this.data?.namcComment
    this.supplierComment = this.data?.supplierComment
    this.scanComment = this.data?.scanComment
    this.namcDate = this.data?.orderDueDate
    this.deliveryduedate = this.formatDateToISO(this.data?.orderDueDate)
    this.supplierPickupDate = this.formatDateToISO(this.data.supplierPickupDate);
    this.shippingDateTime = this.data?.shippingDateTime
    this.manifestNumber = this.data?.manifestNumber
    this.lifeCycleCode = this.data?.lifeCycleCode
    this.sendToSapFlag = this.data?.sendToSapFlag
    this.poLineNo = this.data?.poLineNo
    this.aribaPrNumber = this.data?.aribaPrNumber
    this.aribaOrderStatus = this.data?.aribaOrderStatus
    this.aribaOrderComment = this.data?.aribaOrderComment
    this.palletCode = this.data?.palletCode
    this.mapOrderQuantities();
  }
  formatDateToISO(dateString: string): string {
    const date = new Date(dateString);
    if (!isNaN(date.getTime())) {
      return date.toISOString().split('T')[0];
    }
    return ''
  }
  mapOrderQuantities(): void {
    this.orderQuantities.forEach((item) => {
      if (this.data.hasOwnProperty(item.name)) {
        item.quantity = Number(this.data[item.name]);
      } else {
        item.quantity = 0;
      }
    });
  }

  onSubmit(): void {
    if (!this.validateQuantities()){ return };
    if (!this.isInvalidFutureDate(this.supplierPickupDate)) { return; }
    if (!this.isInvalidFutureDate(this.deliveryduedate)) { return; }
    const payload = {
      trialOrder: {
        orderNumber: this.ordernumber,
        storeAddress: this.storeAddress,
        lineSideAddress: this.lineSideAdress,
        kanbanNumber: this.kanbanNumber,
        poTrackingNumber: this.trackingnumber,
        partName: this.partName,
        partNumber: this.partNumber,
        supplierCode: this.suppliercode,
        supplierName: this.suppliername
      },
      appendTotalOrderQty: this.totalQuantity,
      psmsToRouting: this.response.psmsToRouting,
      appendWorkOrderNumber: this.response.appendWorkOrderNumber,
      appendLifeCycleCode: this.lifeCycleCode,
      appendSendToSapFlag: this.sendToSapFlag,
      appendComment: this.comment,
      appendSupplierPickupDate: this.datePipe.transform(this.supplierPickupDate, 'dd/MM/yyyy'),
      appendDeliveryDate: this.datePipe.transform(this.deliveryduedate, 'dd/MM/yyyy'),
      quantityNames: this.defaultQuantities,
      quantityNamesQty: this.orderQuantities.map(item => item.quantity)
    }
    this.CreateTrailsService.editOrder(payload).subscribe({
      next: (res) => {
        this.router.navigateByUrl('/dummy', { skipLocationChange: true }).then(() => {
          this.router.navigate(['event-order/trial-orders/trialOrderList'], {
            state: { res, myData: this.data.eventId, dummy: true },
          });
        });
      },
      error: (_err) => {
        //No work
      },
    })
  }

  cancel() {
    window.history.back();
  }
}
