import { Component, OnInit } from '@angular/core';
import { HomeService } from 'src/app/services/home.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-new-message',
  templateUrl: './new-message.component.html',
  styleUrls: ['./new-message.component.scss'],
})
export class NewMessageComponent implements OnInit {
  expirationDate = '';
  namcComment = '';
  MandatDate = true;
  MandatComment = true;
  userId: string | undefined;
  errorMessage = '';

  constructor(
    private readonly homeService: HomeService,
    private readonly userService: UserService
  ) {}
  ngOnInit(): void {
    this.userService.userDetails?.subscribe({
      next: (user) => {
        this.userId = user?.userId;
      },
    });
  }

  cancel() {
    window.history.back();
  }

  validateFields() {
    this.MandatDate = true; // Initialize explicitly
    this.MandatComment = true; // Initialize explicitly

    setTimeout(() => {
      this.MandatDate = true;
      this.MandatComment = true;
    }, 3000);
    if (!this.expirationDate) { this.MandatDate = false; }
    if (!this.namcComment) { this.MandatComment = false; }
    if (this.MandatComment && this.MandatDate) { this.submitMessage(); }
  }

  submitMessage() {
    this.homeService
      .sendMessage(this.namcComment, this.expirationDate, this.userId ?? '')
      .subscribe({
        next: (_response: { result: string }) => {
          window.history.back();
        },
        error: () => {
          this.errorMessage = 'Something went wrong. Please Contact System Administrator.'
        },
      });
  }
}
