<app-notification-message
  [errorMessage]="errorMessage"
  [successMessage]="successMessage"
>
</app-notification-message>
<app-grid
  [columnDefs]="columnDefs"
  [displayRowData]="displayRowData"
  class="gridCss"
></app-grid>

<div class="create_footer position-fixed">
  <button
    class="cancel mx-3 mt-2 roboto-bold cancelbtnalign"
    (click)="goBack()"
  >
    BACK
  </button>
</div>
