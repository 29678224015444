import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { TrialOrdersService } from 'src/app/services/event-and-orders/trial-orders.service';
@Component({
  selector: 'app-review-namc',
  templateUrl: './review-namc.component.html',
  styleUrls: ['./review-namc.component.scss'],
})
export class AcceptRejectDialogComponent {
  headerTitle: string;
  actionButtonLabel: string;
  appendOrderNumbers = '';
  comment = '';

  constructor(
    private readonly dialogRef: MatDialogRef<AcceptRejectDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private readonly ngxUiLoaderService: NgxUiLoaderService,
    private readonly trialOrderService: TrialOrdersService
  ) {
    this.headerTitle = data.headerTitle || 'Default Title';
    this.actionButtonLabel = data.actionButtonLabel || 'Default Action';
    this.appendOrderNumbers = data.selectedOrder?.orderNumber;
  }

  onYesClick(): void {
    if (this.headerTitle === 'Accept') {
      this.acceptShipDate();
    } else {
      this.rejectShipDate();
    }
  }

  acceptShipDate() {
    const payload = {
      appendOrderNumbers: this.appendOrderNumbers,
      appendComment: this.data.namcComment,
    };
    this.ngxUiLoaderService.start('accept');
    this.trialOrderService.acceptShipDate(payload)?.subscribe({
      next: (result) => {
        this.ngxUiLoaderService.stop('accept');
        this.dialogRef.close(result);
      },
      error: (err) => {
        this.ngxUiLoaderService.stop('accept');
        const error = err as HttpErrorResponse;
        this.dialogRef.close(error.error);
      },
    });
  }

  rejectShipDate() {
    const payload = {
      appendOrderNumbers: this.appendOrderNumbers,
      appendComment: this.data.namcComment,
    };
    this.ngxUiLoaderService.start('reject');
    this.trialOrderService.rejectShipDate(payload)?.subscribe({
      next: (result) => {
        this.ngxUiLoaderService.stop('reject');
        this.dialogRef.close(result);
      },
      error: (err) => {
        this.ngxUiLoaderService.stop('reject');
        const error = err as HttpErrorResponse;
        this.dialogRef.close(error.error);
      },
    });
  }

  onNoClick(): void {
    this.dialogRef.close('');
  }

  closeDialog(): void {
    this.dialogRef.close('');
  }
}
