<div *ngIf="disableflag">
  <app-notification-message 
  [errorMessage]="errorMessage"
>
</app-notification-message>
</div>
  <div class="form-g">
    <label for="eventDescription">ORDER TRACKING NUMBER</label>
    <input
      id="eventDescription"
      type="text"
      [(ngModel)]="orderTrackingNumber"
      [readonly]="supplierMode "
      [ngClass]="{ 'readonly-input': supplierMode  }"
      style="width: 500px"
      class="form-control"
    />
  </div>
  <div (ngSubmit)="onSubmit()" class="form-container">
    <div class="form-group">
      <div class="label-container">
        <label for="eventDescription">PART NUMBER</label>
      </div>
      <input
        id="eventDescription"
        type="text"
        [(ngModel)]="partNumber"
        [readonly]="supplierMode "
        [ngClass]="{ 'readonly-input': supplierMode  }"
        class="form-control"
      />
    </div>
    <div class="form-group">
      <div class="label-container">
        <label for="eventDescription">PART NAME</label>
      </div>
      <input
        id="eventDescription"
        type="text"
        [(ngModel)]="partName"
        [readonly]="supplierMode "
        [ngClass]="{ 'readonly-input': supplierMode  }"
        class="form-control"
      />
    </div>
    <div *ngIf="currentRole === 'supplier'" class="form-group">
      <div class="label-container">
        <label for="eventType">NAMC DUE DATE</label>
      </div>
      <input
        id="eventType"
        type="text"
        [(ngModel)]="namcDueDate"
        [readonly]="supplierMode "
        [ngClass]="{ 'readonly-input': supplierMode  }"
        class="form-control"
      />
    </div>
    <div *ngIf="currentRole === 'namc'" class="form-group">
      <div class="label-container">
        <label for="eventType">ORDER DELIVERY DUE DATE</label>
      </div>
      <input
        id="eventType"
        type="text"
        [(ngModel)]="orderdeliveryDate"
        [readonly]="supplierMode "
        [ngClass]="{ 'readonly-input': supplierMode  }"
        class="form-control"
      />
    </div>
    <div class="form-group">
      <div class="label-container">
        <label for="projectCode">TOTAL QUANTITY</label>
      </div>
      <input
        id="projectCode"
        [(ngModel)]="totalQuantity"
        [readonly]="supplierMode "
        [ngClass]="{ 'readonly-input': supplierMode  }"
        type="text"
        class="form-control"
      />
    </div>
    <div class="form-group">
      <div class="label-container">
        <label for="supplierPickupDate">SUPPLIER PICKUP DATE</label>
      </div>
      <input
        type="text"
        id="supplierPickupDate"
        [readonly]="supplierMode "
        [ngClass]="{ 'readonly-input': supplierMode  }"
        class="form-control"
        [(ngModel)]="supplierPickupDate"
        name="supplierPickupDate"
      />
    </div>
    <div class="form-group">
      <div class="label-container">
        <label for="supplierShipDate">SUPPLIER SHIP DATE</label>
      </div>
      <input
        type="text"
        id="supplierShipDate"
        [readonly]="supplierMode"
        [ngClass]="{ 'readonly-input': supplierMode }"
        class="form-control"
        [(ngModel)]="supplierShipDate"
        name="supplierShipDate"
      />
    </div>
    <div class="form-group">
      <div class="label-container">
        <div  class="vertical-bar"></div>
        <label for="namcComment">NAMC COMMENT</label>
      </div>
      <textarea
        id="namcComment"
        [readonly]="namcMode"
        [ngClass]="{ 'readonly-input': namcMode }"
        [(ngModel)]="namcComment"
        name="namcComment"
      ></textarea>
    </div>
    <div class="form-group">
      <div class="label-container">
       
        <label for="namcComment">SUPPLIER COMMENT</label>
      </div>
      <textarea
        id="namcComment"
        [(ngModel)]="supplierComment"
        [readonly]="supplierMode"
        [ngClass]="{ 'readonly-input': supplierMode }"
        name="supplierComment"
      ></textarea>
    </div>
    <div class="create_footer fixed-bottom">
      <button
        class="cancel me-3 mt-2 roboto-bold cancelbtnalign"
        (click)="cancel()"
      >
        CANCEL
      </button>
      <button
        *ngIf=" !disableflag"
        class="black-button blackbtnalign me-3 mt-2"
        (click)="openRejectReview()"
      >
        Reject Supplier Ship Date
      </button>
      <button
        *ngIf="!disableflag"
        class="black-button blackbtnalign me-3 mt-2"
        (click)="openAcceptReview()"
      >
        Accept Supplier Ship Date
      </button>
    </div>
  </div>

