import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CreateTrailsService } from 'src/app/services/event-and-orders/create-trails.service';

import { MatDialog } from '@angular/material/dialog';
import { WarningPopupComponent } from 'src/app/core/dialogs/warning-popup/warning-popup.component';
import { SupplierListMap } from 'src/app/core/model/supplierList.model';
import { PerformanceService } from 'src/app/services/suppliers/performance.service';
import { AppendOrderModel } from 'src/app/core/model/trial-order.model';
@Component({
  selector: 'app-append-trails',
  templateUrl: './append-trails.component.html',
  styleUrls: ['./append-trails.component.scss'],
})
export class AppendTrailsComponent implements OnInit {
  defaultQuantities: string[] = [];
  constructor(
    private readonly router: Router,
    private readonly dialog: MatDialog,
    private readonly performanceService: PerformanceService,
    private readonly CreateTrailsService: CreateTrailsService
  ) {}
  supplierCodes: SupplierListMap[] = [];
  numOfOrders = 1;
  orders: any[] = [];
  response: AppendOrderModel = {} as AppendOrderModel;
  eventId = '';
  isFieldsFilled = false;
  showError = false;

  appendOrders() {
    
    if (!this.isFieldsFilled) {
      this.orders = [];
      for (let i = 0; i < this.numOfOrders; i++) {
        this.orders.push({
          poTrackingNumber: '',
          psmsRouting: '',
          supplierCode: '',
          supplierName: '',
          kanbanNo: '',
          partNumber: '',
          partName: '',
          orderDeliveryDate: '',
          orderPickupDate: '',
          trialQty: '',
          assyTrainQty: '',
          cfQty: '',
          peQty: '',
          qeQty: '',
          axwQty: '',
          totalQuantity: '',
          sendToSapFlag: '',
          lifeCycleCode: '',
          plant1Qty: '',
          plant2Qty: '',
          plant3Qty: '',
          comment: '',
          store: '',
          lineSide: '',
          palletCode: '',
          supplierCodeError: false,
          supplierNameError: false,
          partNumberError: false,
          partNameError: false,
          orderDeliveryDateError: false,
          orderPickupDateError: false,
          totalQuantityError: false,
          totalQuantityMismatch: false,
          pickupDateError: false,
          deliveryDateError: false,
          partNumberLengthError: false,
          invalidPickupDateError: false,
          invalidDeliveryDateError: false,
          orderNumber: this.response.trialOrder.orderNumber,
        });
      }
    }
  }
  checkIfFilled() {
    this.isFieldsFilled = this.orders.some((order) => {
      return (
        order.supplierCode ||
        order.supplierName ||
        order.partName ||
        order.poTrackingNumber
      );
    });
  }
  ngOnInit(): void {
    this.eventId = String(history.state?.myData);
    this.fetchSupplierCodes();
    this.CreateTrailsService.getQuantityNames(this.eventId)?.subscribe(
      (res) => {
        this.response = res;
        this.defaultQuantities = res.trialEventQuantityNameLst.map(
          (element) => {
            return element.inputBox;
          }
        );
      }
    );
  }
  fetchSupplierCodes(): void {
    this.performanceService.suppliersDropDown()?.subscribe({
      next: (res) => {
        this.supplierCodes = res;
      },
      error: (_error: Error) => { },
    });
  }
  onSupplierCodeChange(event: Event, orderIndex: number): void {
    const selectedCode = (event.target as HTMLSelectElement).value;

    const selectedSupplier = this.supplierCodes.find(
      (supplier) => supplier.supplierCode === selectedCode
    );

    if (selectedSupplier) {
      this.orders[orderIndex].supplierName = selectedSupplier.supplierName;
    }
  }
  isValidDateFormat(dateString: string): boolean {
    const dateRegex = /^(0[1-9]|1[0-2])\/(0[1-9]|[12]\d|3[01])\/\d{4}$/;

    if (!dateRegex.test(dateString)) {
      return false;
    }

    const parsedDate = new Date(dateString);
    return parsedDate instanceof Date && !isNaN(parsedDate.getTime());
  }
  cancel() {
    const dialogRef = this.dialog.open(WarningPopupComponent, {
      width: '400px',
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'Success') {
        window.history.back();
      }
    });
  }
  getCommaSeparatedValues(key: string) {
    return this.orders.map((order) => order[key]).join(',');
  }
  onSubmit() {
    this.orders.forEach((order) => {
      order.supplierCodeError = !order.supplierCode;
      order.supplierNameError = !order.supplierName;
      order.partNumberError = !order.partNumber;
      order.partNameError = !order.partName;
      order.orderDeliveryDateError = !order.orderDeliveryDate;
      order.orderPickupDateError = !order.orderPickupDate;
      order.totalQuantityError = !order.totalQuantity;
      order.partNumberLengthError = order.partNumber.length !== 12;

      const today = new Date();
      const deliveryDate = new Date(order.orderDeliveryDate);
      const pickupDate = new Date(order.orderPickupDate);

      order.deliveryDateError = deliveryDate <= today;
      order.pickupDateError = pickupDate <= today;
      const total =
        (parseInt(order.trialQty, 10) || 0) +
        (parseInt(order.assyTrainQty, 10) || 0) +
        (parseInt(order.cfQty, 10) || 0) +
        (parseInt(order.peQty, 10) || 0) +
        (parseInt(order.qeQty, 10) || 0) +
        (parseInt(order.axwQty, 10) || 0);
      order.deliveryDateError = !this.isValidDateFormat(
        order.orderDeliveryDate
      );
      order.pickupDateError = !this.isValidDateFormat(order.orderPickupDate);
      const totalQuantityString = total.toString();
      order.totalQuantityMismatch = order.totalQuantity !== totalQuantityString;
    });

    const isValid = this.orders.every(
      (order) =>
        order.supplierCode &&
        order.supplierName &&
        order.partNumber &&
        order.partNumberLengthError === false &&
        order.partName &&
        order.orderDeliveryDate &&
        !order.invalidDeliveryDateError &&
        !order.deliveryDateError &&
        order.orderPickupDate &&
        !order.invalidPickupDateError &&
        !order.pickupDateError &&
        order.totalQuantity &&
        !order.totalQuantityMismatch
    );
    if (!isValid) {
      this.showError = true;
    } else {
      const generateQuantityNames = () => {
        const quantityNames = this.response.trialEventQuantityNameLst.map(
          (element: { qNameBox: string }) => element.qNameBox
        );
        const result = Array.from({ length: this.orders.length }, () => [
          ...quantityNames,
        ]);

        return result;
      };
      this.showError = false;
      const payload = {
        eventId: this.eventId,
        appendOrderNumber: this.getCommaSeparatedValues('orderNumber'),
        appendKanbanNumber: this.getCommaSeparatedValues('kanbanNo'),
        appendSupplierCode: this.getCommaSeparatedValues('supplierCode'),
        appendSupplierNames: this.getCommaSeparatedValues('supplierNames'),
        appendPartNumber: this.getCommaSeparatedValues('partNumber'),
        appendPartName: this.getCommaSeparatedValues('partName'),
        appendDeliveryDate: this.getCommaSeparatedValues('orderDeliveryDate'),
        appendPlant1Qty: this.getCommaSeparatedValues('plant1Qty'),
        appendPlant2Qty: this.getCommaSeparatedValues('plant2Qty'),
        appendPlant3Qty: this.getCommaSeparatedValues('plant3Qty'),
        appendTotalOrderQty: this.getCommaSeparatedValues('totalQuantity'),
        appendComment: this.getCommaSeparatedValues('comment'),
        appendStore: this.getCommaSeparatedValues('store'),
        appendLineSide: this.getCommaSeparatedValues('lineSide'),
        appendPalletCode: this.getCommaSeparatedValues('palletCode'),
        appendPOTrackingNumber:
          this.getCommaSeparatedValues('poTrackingNumber'),
        appendToRoute: this.getCommaSeparatedValues('psmsToRoute'),
        appendQuantitynames: generateQuantityNames(),
        appendQuantityQty: this.orders.map((order) =>
          [
            order.trialQty || 0,
            order.assyTrainQty || 0,
            order.cfQty || 0,
            order.peQty || 0,
            order.qeQty || 0,
            order.axwQty || 0,
          ].map((qty) => qty.toString())
        ),

        psmsFromRouting: this.response.psmsFromRouting,
        psmsToRouting: this.response.psmsToRouting,
        appendSupplierPickupDate:
          this.getCommaSeparatedValues('orderPickupDate'),
        appendLifeCycleCode: this.getCommaSeparatedValues('lifeCycleCode'),
        appendSendToSapFlag: this.getCommaSeparatedValues('sendToSapFlag'),
        appendSpecialistEmailAddress: '',
        calculateOrderNumber: this.response.calculateOrderNumber,
        trialOrder: {
          statusId: this.response.trialOrder.statusId,
          orderNumber: this.response.trialOrder.orderNumber,
          promiseDate: null,
          isArchiveFlag: this.response.trialOrder.isArchiveFlag,
          totalOrderQuantity: '',
          namcComent: '',
          supplierPickupDate: '',
          supplierCode: '',
          supplierName: '',
          kanbanNumber: '',
          partNumber: '',
          partName: '',
          plant1Qty: '',
          plant2Qty: '',
          plant3Qty: '',
          deliveryDueDate: '',
          manifestNumber: '',
          completionDate: '',
          supplierComment: '',
          storeAddress: '',
          lineSideAddress: '',
          poTrackingNumber: '',
          workOrderNumber: this.response.appendWorkOrderNumber,
          lifeCycleCode: this.response.appendLifeCycleCode,
          owkLabelLine1: '',
          owkLabelLine2: '',
          sendToSapFlag: '',
          aribaPrNumber: '',
          aribaOrderStatus: '',
          aribaOrderComment: '',
          poLineNo: '',
          requestForSuppShipDtChng: '',
          requestForSuppShipcmmntChng: '',
          palletCode: '',
          naPipeStatus: '',
          eventId: {
            eventId: this.eventId,
            eventTypeId: this.response.trialOrder.eventId.eventTypeId,
            namcCode: this.response.trialOrder.eventId.namcCode,
            projectCode: this.response.trialOrder.eventId.projectCode,
            eventName: this.response.trialOrder.eventId.eventName,
            partsSource: this.response.trialOrder.eventId.partsSource,
            eventDescription: this.response.trialOrder.eventId.eventDescription,
            psmsFromRouting: this.response.trialOrder.eventId.psmsFromRouting,
            psmsToRouting: this.response.trialOrder.eventId.psmsToRouting,
            shipmentLocation: this.response.trialOrder.eventId.shipmentLocation,
            shipmentAddress: this.response.trialOrder.eventId.shipmentAddress,
            shipmentCity: this.response.trialOrder.eventId.shipmentCity,
            shipmentState: this.response.trialOrder.eventId.shipmentState,
            shipmentCountry: this.response.trialOrder.eventId.shipmentCountry,
            shipmentPostalCode:
              this.response.trialOrder.eventId.shipmentPostalCode,
            dockCode: this.response.trialOrder.eventId.dockCode,
            orderNumberSeries:
              this.response.trialOrder.eventId.orderNumberSeries,
            logisticProvider: this.response.trialOrder.eventId.logisticProvider,
            shipmentScheduledTracking:
              this.response.trialOrder.eventId.shipmentScheduledTracking,
            assignQPCToTrial: this.response.trialOrder.eventId.assignQPCToTrial,
            subRouteNameOverride:
              this.response.trialOrder.eventId.subRouteNameOverride,
            mainRouteNameOverride:
              this.response.trialOrder.eventId.mainRouteNameOverride,
            storesAddressNameOverride:
              this.response.trialOrder.eventId.storesAddressNameOverride,
            carryoverPartsOrdering:
              this.response.trialOrder.eventId.carryoverPartsOrdering,
            completionDate: this.response.trialOrder.eventId.completionDate,
            packagingNeed: this.response.trialOrder.eventId.packagingNeed,
            eventStatus: this.response.trialOrder.eventId.eventStatus,
            ringiNumber: this.response.trialOrder.eventId.ringiNumber,
            isNAQD: this.response.trialOrder.eventId.isNAQD,
            trialEventQuantityNameLst: [],
          },
          createdBy: 'sbjoraj',
          createTimestamp: this.response.trialOrder.createTimestamp,
          revisionDate: this.response.trialOrder.revisionDate,
        },
        quantityNames: [],
        quantityNamesQty: [],
      };

      this.CreateTrailsService.submitOrder(payload)?.subscribe({
        next: (res) => {
          this.router
            .navigateByUrl('/dummy', { skipLocationChange: true })
            .then(() => {
              this.router.navigate(
                ['event-order/trial-orders/trialOrderList'],
                {
                  state: { res, myData: this.eventId, dummy: true },
                }
              );
            });
        },
        error: (_err) => { },
      });
    }
  }
}