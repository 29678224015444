import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
@Component({
  selector: 'app-ship-date-namc',
  templateUrl: './ship-date-namc.component.html',
  styleUrls: ['./ship-date-namc.component.scss'],
})
export class ShipDateNamcComponent {
  constructor(
    private readonly dialogRef: MatDialogRef<ShipDateNamcComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  onYesClick(): void {
    // Add your 'Yes' logic here
    this.dialogRef.close(true);
  }

  onNoClick(): void {
    // Add your 'No' logic here
    this.dialogRef.close(false);
  }

  closeDialog(): void {
    this.dialogRef.close();
  }
}
