import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { MatExpansionPanel } from '@angular/material/expansion';
import { Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import {
  Category,
  Document,
  TrainingLists,
} from 'src/app/core/model/manage-online-namc';
import { ManageOnlineTrainingService } from 'src/app/services/tmnaNamc/manage-online-training.service';
@Component({
  selector: 'app-online-training-namc-documents',
  templateUrl: './online-training-namc-documents.component.html',
  styleUrls: ['./online-training-namc-documents.component.scss'],
})
export class OnlineTrainingNamcDocumentsComponent implements OnInit {
  @ViewChildren(MatExpansionPanel) panels!: QueryList<MatExpansionPanel>;
  allPanelsCollapsed = true;
  documents: {
    catid: string;
    catnm: string;
    documentList: TrainingLists[];
  }[] = [];
  category!: string;
  signedUrl!: string;
  errorMessage: string | undefined;
  successMessage: string | undefined;
  wrongMessage = 'Something went wrong. Please Contact System Adminstrator.';

  constructor(
    private readonly router: Router,
    private readonly manageService: ManageOnlineTrainingService,
    private readonly ngxUiLoaderService: NgxUiLoaderService
  ) {}
  headers: string[] = ['document', 'date', 'fileSize'];

  collapseAll() {
    this.allPanelsCollapsed = !this.allPanelsCollapsed;
    this.panels.forEach((panel) => {
      if (this.allPanelsCollapsed) {
        panel.open();
      } else {
        panel.close();
      }
    });
  }

  ngOnInit(): void {
    this.category =
      this.router.url === '/supplier/online-training' ||
      this.router.url === '/help/online-training'
        ? 'S'
        : 'N';
    this.fetchDocuments();
  }

  fetchDocuments() {
    this.ngxUiLoaderService.start('loader-01');
    const categoryObject: {
      catid: string;
      catnm: string;
      documentList: TrainingLists[];
    }[] = [];
    this.manageService.onLoadTraining(this.category)?.subscribe({
      next: (res: Document) => {
        this.ngxUiLoaderService.stop('loader-01');
        res.categoryList.forEach((value: Category) => {
          categoryObject.push({
            catid: value.categoryid,
            catnm: value.categoryname,
            documentList: res.trainingList.filter((element: TrainingLists) => {
              return element.categoryid === value.categoryid;
            }),
          });
        });
        categoryObject.forEach((element) => {
          if (element.catnm) {
            this.documents.push({
              catid: element.catid,
              catnm: element.catnm,
              documentList: element.documentList.map((item: TrainingLists) => {
                return {
                  ...item,
                  fileSize: Number(item.filesizetext)
                    ? this.convertToRespectiveByteFormat(
                        Number(item.filesizetext)
                      )
                    : item.filesizetext,
                };
              }),
            });
          }
        });
      },
      error: (_error: Error) => {
        this.ngxUiLoaderService.stop('loader-01');
        this.errorMessage = this.wrongMessage;
        setTimeout(() => {
          this.errorMessage = undefined;
        }, 5000);
      },
    });
  }

  convertToRespectiveByteFormat(value: number) {
    if (value === 0) {
      return '0 Bytes';
    }

    const k = 1024;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    const i = Math.floor(Math.log(value) / Math.log(k));
    const convertedValue = parseFloat((value / Math.pow(k, i)).toFixed(2));

    return `${convertedValue} ${sizes[i]}`;
  }

  downloadDocument(documentId: string, documentName: string) {
    this.ngxUiLoaderService.start('loader-01');
    this.manageService.downloadDocument(documentId)?.subscribe({
      next: (res: { message?: string; result: boolean }) => {
        this.ngxUiLoaderService.stop('loader-01');
        if (res.message && res.result) {
          this.signedUrl = res.message;
          this.downloadFile(documentName);
        } else {
          this.errorMessage =
            'File Not Found, please contact System Administrator';
          setTimeout(() => {
            this.errorMessage = undefined;
          }, 5000);
        }
      },
      error: (_error) => {
        this.ngxUiLoaderService.stop('loader-01');
        this.errorMessage = 'Failed to download document';
      },
    });
  }

  downloadFile(documentName: string) {
    const fileExtension = documentName.split('.').pop()?.toLowerCase();

    if (fileExtension === 'mp4' || fileExtension === 'pdf') {
      try {
        if (fileExtension === 'mp4') {
          const videoTab = window.open();
          if (videoTab) {
            videoTab.document.write(`
              <video controls autoplay style="width: 100%; height: 100%;">
                <source src="${this.signedUrl}" type="video/mp4">
                Your browser does not support the video tag.
              </video>
            `);
            videoTab.document.title = documentName;
          } else {
            this.errorMessage = 'Failed to open new tab';
          }
        } else if (fileExtension === 'pdf') {
          window.open(this.signedUrl, '_blank');
        }
      } catch (_error) {
        this.errorMessage = 'Failed to fetch and convert file to base64';
      }
    } else {
      const link = document.createElement('a');
      link.href = this.signedUrl;
      link.download = documentName;
      link.click();
    }
  }
}
