import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ColDef } from 'ag-grid-community';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { LateOrders } from 'src/app/core/model/performance.model';
import { PerformanceService } from 'src/app/services/suppliers/performance.service';

@Component({
  selector: 'app-late-order-grid',
  templateUrl: './late-order-grid.component.html',
  styleUrls: ['./late-order-grid.component.scss'],
})
export class LateOrderGridComponent implements OnInit {
  monthAndYear!: string;
  supplierCode!: string;
  errorMessage: string | undefined;
  successMessage: string | undefined;
  displayRowData!: LateOrders[];
  columnDefs: ColDef[] = [];
  monthMap: { [key: string]: string } = {
    Jan: '1',
    Feb: '2',
    Mar: '3',
    Apr: '4',
    May: '5',
    Jun: '6',
    Jul: '7',
    Aug: '8',
    Sep: '9',
    Oct: '10',
    Nov: '11',
    Dec: '12',
  };

  constructor(
    private readonly ngxUiLoaderService: NgxUiLoaderService,
    private readonly performanceService: PerformanceService,
    private readonly route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.route?.queryParams?.subscribe((params) => {
      this.monthAndYear = params['monthAndYear'];
      this.supplierCode = params['supplierCode'];
    });
    this.getColDefs();
    this.lateOrdersParticulars(this.monthAndYear, this.supplierCode);
  }

  lateOrdersParticulars(monthAndYear: string, supplierCode: string) {
    const [month, year] = monthAndYear.split(' ');
    this.ngxUiLoaderService.start('loader-01');
    const payload = {
      suppliercode: supplierCode,
      month: this.monthMap[month],
      year: year,
    };
    this.performanceService.lateOrdersParticulars(payload)?.subscribe({
      next: (res: LateOrders[]) => {
        this.ngxUiLoaderService.stop('loader-01');
        this.displayRowData = res;
      },
      error: (_error: Error) => {
        this.ngxUiLoaderService.stop('loader-01');
        this.setErrorMessage('Something went wrong');
      },
    });
  }

  setErrorMessage(errorMessage: string) {
    this.errorMessage = errorMessage;
    setTimeout(() => {
      this.successMessage = undefined;
      this.errorMessage = undefined;
    }, 5000);
  }

  getColDefs() {
    this.columnDefs = [
      {
        headerName: 'Supplier Code',
        field: 'supplierCode',
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        sortable: true,
        unSortIcon: true,
        autoHeight: true,
        minWidth: 90,
        maxWidth: 90,
      },
      {
        headerName: 'Supplier Name',
        field: 'supplierName',
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        sortable: true,
        unSortIcon: true,
        autoHeight: true,
        minWidth: 650,
      },
      {
        headerName: 'Order Number',
        field: 'orderNumber',
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        sortable: true,
        unSortIcon: true,
        autoHeight: true,
        minWidth: 150,
      },
      {
        headerName: 'Part Number',
        field: 'partNumber',
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        sortable: true,
        unSortIcon: true,
        autoHeight: true,
        minWidth: 120,
      },
      {
        headerName: 'Part Name',
        field: 'partName',
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        sortable: true,
        unSortIcon: true,
        autoHeight: true,
        minWidth: 230,
      },
      {
        headerName: 'Total Order Qty',
        field: 'totalOrderQuantity',
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        sortable: true,
        unSortIcon: true,
        autoHeight: true,
        minWidth: 150,
      },
      {
        headerName: 'Issue Date',
        field: 'orderCreateTime',
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        sortable: true,
        unSortIcon: true,
        autoHeight: true,
        minWidth: 150,
      },
      {
        headerName: 'Due Date',
        field: 'dueDate',
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        sortable: true,
        unSortIcon: true,
        autoHeight: true,
        minWidth: 150,
      },
      {
        headerName: 'Promise Date',
        field: 'promiseDate',
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        sortable: true,
        unSortIcon: true,
        autoHeight: true,
        minWidth: 150,
      },
      {
        headerName: 'Receive Date',
        field: 'receivedDate',
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        sortable: true,
        unSortIcon: true,
        autoHeight: true,
        minWidth: 150,
      },
    ];
  }
  
  goBack() {
    window.history.back();
  }
}
