import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import {
  ContactListMap,
  ContactMap,
  SupplierListMap,
} from 'src/app/core/model/contacts.model';
import { ContactsService } from 'src/app/services/suppliers/contacts.service';
type StringArray = ContactMap[];

type DataItem = {
  supplierCode: string;
  supplierName: string;
};

@Component({
  selector: 'app-contacts',
  templateUrl: './contacts.component.html',
  styleUrls: ['./contacts.component.scss'],
})
export class ContactsComponent implements OnInit {
  myForm: FormGroup;
  supplierCodeList: SupplierListMap[] = [];
  contactDetails!: StringArray[];
  errorMessage: string | undefined;
  successMessage: string | undefined;
  hideDetails = false;
  contactList!: string[];
  supplierName!: string | undefined;

  constructor(
    private readonly fb: FormBuilder,
    private readonly ngxUiLoaderService: NgxUiLoaderService,
    private readonly contactsService: ContactsService,
    private readonly router: Router
  ) {
    this.myForm = this.fb.group({
      supplierCode: ['', Validators.required],
      supplierName: ['', Validators.required],
      customSupplierCode: [''],
    });
  }
  ngOnInit(): void {
    this.fetchSupplierDetails();
  }

  fetchSupplierDetails() {
    this.ngxUiLoaderService.start('loader-01');
    this.contactsService.contactsDropDown()?.subscribe({
      next: (res) => {
        this.supplierCodeList = res;
        this.ngxUiLoaderService.stop('loader-01');
      },
      error: (_err) => {
        this.ngxUiLoaderService.stop('loader-01');
        this.setErrorMessage('Something went wrong');
      },
    });
  }

  onSubmit() {
    if (this.myForm.valid || this.myForm.value.customSupplierCode) {
      this.callingDetails();
    } else {
      this.myForm.markAllAsTouched();
    }
  }

  callingDetails() {
    this.ngxUiLoaderService.start('loader-01');
    const payload = {
      supplierId: this.myForm.value.customSupplierCode
        ? this.myForm.value.customSupplierCode
        : this.myForm.value.supplierCode,
      selectedSupplierId: this.myForm.value.customSupplierCode
        ? this.myForm.value.customSupplierCode
        : this.myForm.value.supplierCode,
      userId: 'Sbojaraj',
    };
    this.contactsService.loadContactDetails(payload)?.subscribe({
      next: (res) => {
        this.supplierName = res?.supplierMapResult?.find(
          (item: SupplierListMap) =>
            item.supplierCode === payload.selectedSupplierId.substring(0, 4)
        )?.supplierName;
        if (res?.supplierContactList?.length !== 0) {
          this.hideDetails = true;
          this.contactDetails = [];
          this.contactList = [];
          const filteredData: ContactMap[] = this.setFilteredData(
            this.myForm.value.customSupplierCode,
            res
          );

          const groupedBySupplierCd = this.groupBasedOnSupplier(filteredData);

          this.sortContactList();

          this.groupContactDetails(groupedBySupplierCd);

          if (this.contactDetails.length === 0) {
            this.setErrorMessage(
              `No contacts found for [${this.myForm.value.customSupplierCode}
                ] `
            );
          }
        } else {
          this.contactList = [];
          this.setErrorMessage(
            `${this.myForm.value.supplierName} [${
              this.myForm.value.supplierCode
                ? this.myForm.value.supplierCode
                : this.myForm.value.customSupplierCode
            }]
              details not found`
          );
        }
        this.ngxUiLoaderService.stop('loader-01');
      },
      error: (_error: Error) => {
        this.ngxUiLoaderService.stop('loader-01');
        this.setErrorMessage('Something went wrong');
      },
    });
  }

  setFilteredData(
    customSupplierCode: string,
    res: ContactListMap
  ): ContactMap[] {
    if (customSupplierCode) {
      return res.supplierContactList?.filter(
        (item: ContactMap) => customSupplierCode === item.suppliercode
      );
    } else {
      return res.supplierContactList;
    }
  }

  groupBasedOnSupplier(filteredData: ContactMap[]) {
    return filteredData.reduce((result: any, item: ContactMap) => {
      const key = item.suppliercode;
      if (!result[key]) {
        result[key] = [];
        this.contactList?.push(key);
      }
      result[key].push(item);
      return result;
    }, {});
  }

  sortContactList() {
    this.contactList.sort((a, b) => {
      const numA = parseInt(a, 10);
      const numB = parseInt(b, 10);

      return numA - numB;
    });
  }

  groupContactDetails(groupedBySupplierCd: any) {
    this.contactDetails = Object.keys(groupedBySupplierCd)
      .sort((a, b) => {
        const numA = parseInt(a, 10);
        const numB = parseInt(b, 10);

        return numA - numB;
      })
      .map((key) => groupedBySupplierCd[key]);
  }

  setErrorMessage(errorMessage: string) {
    this.errorMessage = errorMessage;
    setTimeout(() => {
      this.successMessage = undefined;
      this.errorMessage = undefined;
    }, 5000);
  }

  supplierNameChange() {
    const selectedSupplierCode = this.supplierCodeList.find(
      (element) => element.supplierName === this.myForm.value.supplierName
    )?.supplierCode;
    this.myForm.patchValue({
      supplierCode: selectedSupplierCode,
      customSupplierCode: '',
    });
  }

  supplierCodeChange() {
    const selectedSupplierName = this.supplierCodeList.find(
      (element) => element.supplierCode === this.myForm.value.supplierCode
    )?.supplierName;
    this.myForm.patchValue({
      supplierName: selectedSupplierName,
      customSupplierCode: '',
    });
  }

  customSupplierCodeChange() {
    this.myForm.patchValue({
      supplierName: '',
      supplierCode: '',
    });
    this.myForm.get('supplierCode')?.setErrors(null);
  }
  cancel() {
    this.router.navigate(['/home']);
  }
}
