import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { handleError } from '../core/common/common';

@Injectable({
  providedIn: 'root',
})
export class HomeService {
  baseApiUrl: string;
  constructor(private readonly http: HttpClient) {
    this.baseApiUrl = environment.baseAPIURL;
  }

  getSuppliersList() {
    return this.http
      .get<{ supplierCode: string; supplierName: string }[]>(
        `${this.baseApiUrl}/home/suppliercodes`,
        {
          withCredentials: true,
          responseType: 'json',
        }
      )
      .pipe(catchError(handleError));
  }

  getMessages() {
    return this.http
      .get<
        {
          msgId: number;
          msgText: string;
          msgExpiry: string;
          isActive: string;
        }[]
      >(`${this.baseApiUrl}/home/TMNANotification`, {
        withCredentials: true,
        responseType: 'json',
      })
      .pipe(catchError(handleError));
  }

  getCountOfOrders(payload: {
    criteriaColumn: string;
    criteriaValue: string;
    supplierCode: string | undefined;
    userId: string | undefined;
  }) {
    return this.http
      .post<
        {
          attrKey: string;
          attrVal: string;
        }[]
      >(`${this.baseApiUrl}/home/quicklinks/getOrdersCounts`, payload, {
        withCredentials: true,
        responseType: 'json',
      })
      .pipe(catchError(handleError));
  }

  sendMessage(message: string, expiryDate: string, userId: string) {
    const payload = {
      message: message,
      messageExpiryDate: new Date(expiryDate).toISOString(),
      userId: userId,
    };
    return this.http
      .post<{ result: string }>(`${this.baseApiUrl}/home/TMNANotification`, payload, {
        withCredentials: true,
        responseType: 'json',
      })
      .pipe(catchError(handleError));
  }

  getQuickLinkOrderDetails(orderDetails: string){
    return this.http
      .get<any>(
        `${this.baseApiUrl}/home/quick-links?id=${orderDetails}`,
        {
          withCredentials: true,
          responseType: 'json',
        }
      )
      .pipe(catchError(handleError));
  }
}
