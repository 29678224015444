
<app-notification-message
  [errorMessage]="errorMessage"
  [successMessage]="successMessage"
>
</app-notification-message>
<div class="container">
  <div class="left-section">
    <form [formGroup]="myForm" *ngIf="!isSupplier">
      <div class="input-section">
        <label for="code" class="fw-bold">Supplier Code:</label>
        <p-dropdown
          formControlName="supplierCode"
          [options]="supplierCodeList"
          optionLabel="supplierCode"
          optionValue="supplierCode"
          class="dropdownOptions"
          placeholder="ALL"
          [virtualScroll]="true"
          scrollHeight="200px"
          [filter]="true"
          [virtualScrollItemSize]="30"
          (onChange)="supplierCodeChange()"
        >
        </p-dropdown>
        <label for="id" class="fw-bold">Supplier Name:</label>
        <p-dropdown
          formControlName="supplierName"
          [options]="supplierCodeList"
          [virtualScrollItemSize]="30"
          optionLabel="supplierName"
          optionValue="supplierName"
          class="dropdownOptions"
          placeholder="ALL"
          [virtualScroll]="true"
          scrollHeight="200px"
          [filter]="true"
          (onChange)="supplierNameChange()"
        ></p-dropdown>
        <button class="b2 add" (click)="onSubmit()">SUBMIT</button>
      </div>
    </form>
    <table>
      <thead>
        <tr>
          <th class="caption">LATE ORDER OF LAST 12 MONTHS</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let month of originalLabels; let i = index">
          <td class="modified-row" *ngIf="!lateOrders?.[i]">
            No Late Order in {{ month }}
          </td>
          <td
            class="callable-row"
            (click)="lateOrdersParticulars(month)"
            *ngIf="lateOrders?.[i]"
            onkeypress="lateOrdersParticulars(month)"
          >
            view {{ month }} Late Order
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <div *ngIf="emptyCheck else emptyMessage" class="charts-section">
    <div class="upperText">
      Please Select Supplier Code From Dropdown or Enter Custom Supplier
      Code To Search
    </div>
    <div class="lineChart">
      <div class="mb-2 fw-bold">
        {{ selectedSupplier ? selectedSupplier : "ALL" }}
        TOTAL ANNUAL PERFORMANCE
      </div>
      <canvas
        baseChart
        [datasets]="lineChartData"
        [labels]="lineChartLabels"
        [options]="lineChartOptions"
        [legend]="lineChartLegend"
        [type]="lineChartType"
      >
      </canvas>
    </div>
    <div class="barChart">
      <div class="mb-2 fw-bold">
        {{ selectedSupplier ? selectedSupplier : "ALL" }}
        TOTAL MONTHLY PERFORMANCE
      </div>
      <canvas
        baseChart
        [datasets]="barChartData"
        [labels]="barChartLabels"
        [options]="barChartOptions"
        [legend]="barChartLegend"
        [type]="barChartType"
      >
      </canvas>
    </div>
  </div>
  <ng-template #emptyMessage>
    <div class="charts-section text-center">
      <span class="roboto-bold">{{showNullMessage}}</span>
    </div>
  </ng-template>
</div>
