import { Injectable } from '@angular/core';
import * as FileSaver from 'file-saver-es';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import * as XLSX from 'xlsx-js-style';
const EXCEL_TYPE =
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Injectable({
  providedIn: 'root',
})
export class ExcelService {
  uploadedFile: any;

  constructor(private readonly ngxUiLoaderService: NgxUiLoaderService) {}
  public exportAsExcelFile(
    json: any[],
    excelFileName: string,
    headers: string[]
  ): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    let sheetPro: any = {};
    sheetPro = { Sheets: { Sheet1: worksheet }, SheetNames: ['Sheet1'] };
    const range = XLSX.utils.decode_range(worksheet['!ref'] as string);
    for (let C = range.s.c; C <= range.e.c; C++) {
      const address = XLSX.utils.encode_col(C) + '1'; // <-- first row
      if (!worksheet[address]) {
        continue;
      }
      worksheet[address].v = headers[C];
    }
    const wscols = [];
    for (const head of headers) {
      wscols.push({ wch: head.length + 15 });
    }
    worksheet['!cols'] = wscols;
    this.setColumnInformation(headers, worksheet);
    const workbook: XLSX.WorkBook = sheetPro;
    const excelBuffer: any = XLSX.write(workbook, {
      bookType: 'xlsx',
      type: 'array',
    });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }

  setColumnInformation(headers: string[], worksheet: XLSX.WorkSheet) {
    const colNames = [];
    let i = 0;
    let j = 65;
    let k = 0;

    for (; i < headers.length; i++) {
      if (k === 0) {
        colNames.push(String.fromCharCode(j) + '1');
      } else {
        colNames.push(`${String.fromCharCode(k)}${String.fromCharCode(j)}1`);
      }
      j++;
      if (j > 90) {
        j = 65;
        if (k === 0) {
          k = 65;
        } else {
          k++;
        }
      }
    }

    for (const itm of colNames) {
      if (worksheet[itm]) {
        worksheet[itm].s = {
          font: { bold: true },
          fill: {
            patternType: 'solid',
            fgColor: { rgb: 'b2b2b2' },
            bgColor: { rgb: 'b2b2b2' },
            border: 'solid',
          },
        };
      }
    }
  }

  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], { type: EXCEL_TYPE });
    FileSaver.saveAs(data, fileName + EXCEL_EXTENSION);
  }
}
