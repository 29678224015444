<div class="popup-container">
  <button class="close black-button" (click)="closeDialog()">
    <mat-icon>close</mat-icon>
  </button>

  <h2 class="p-3 mb-0 roboto-medium">{{ headerTitle }} Orders</h2>
  <hr id="shadow" />
  <br />
  <div class="dialog-body">
    <p>
      Are you sure you want to {{ headerTitle }} Supplier Ship Date for the
      selected order
    </p>
  </div>
  <div class="dialog-actions">
    <button mat-button class="yes-button" (click)="onYesClick()">YES</button>
    <button mat-button class="no-button" (click)="onNoClick()">NO</button>
  </div>
</div>
