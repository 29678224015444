import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { TrialOrdersService } from 'src/app/services/event-and-orders/trial-orders.service';
import { TrialOrderDetails } from '../../model/trial-order.model';

@Component({
  selector: 'app-sap-interface',
  templateUrl: './sap-interface.component.html',
  styleUrls: ['./sap-interface.component.scss'],
})
export class SapInterfaceComponent implements OnInit {
  descriptiveText!: string;
  warningText = '';
  buttonClicked!: string;
  ordersNumber: string[] = [];
  selectedRow: TrialOrderDetails[] = [];

  constructor(
    public dialogRef: MatDialogRef<SapInterfaceComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private readonly ngxUiLoaderService: NgxUiLoaderService,
    private readonly trialOrderService: TrialOrdersService
  ) {
    this.buttonClicked = data.headerTitle || 'Default Title';
    this.selectedRow = this.data.selectedOrder;
  }

  ngOnInit(): void {
    if (this.buttonClicked === 'SendToSAP') {
      this.descriptiveText = `These are Purchase Orders. All Unsent Trial Event orders will be transmitted.
      Are you sure you want to sent orders to SAP Ariba?`;
      this.warningText = `Please make sure you are not refreshing the page after the request is sent to SAP.`;
    } else if (this.buttonClicked === 'EnableSAP') {
      this.descriptiveText = `All the selected Purchase Orders will be enabled to send to SAP Ariba. 
      Are you sure you want to enable these Purchase Orders?`;
    } else if (this.buttonClicked === 'DisableSAP') {
      this.descriptiveText = `All the selected Purchase Orders will be disabled to send to SAP Ariba.
     Are you sure you want to disable these Purchase Orders?`;
    }
  }

  onNoClick(): void {
    this.dialogRef.close('');
  }

  public saveClick() {
    if (
      this.buttonClicked === 'EnableSAP' ||
      this.buttonClicked === 'DisableSAP'
    ) {
      this.sapInterfaceApiCall();
    } else {
      this.sendToSapApiCall();
    }
  }

  sapInterfaceApiCall() {
    for (const order of this.selectedRow) {
      this.ordersNumber.push(order.orderNumber);
    }
    const payload = {
      orderNumbers: this.ordersNumber,
      toggleFlag: this.buttonClicked === 'EnableSAP' ? 'E' : 'D',
    };
    this.ngxUiLoaderService.start('griddata');
    this.trialOrderService.toggleSapInterface(payload)?.subscribe({
      next: (result) => {
        this.dialogRef.close(result);
      },
      error: (err) => {
        this.ngxUiLoaderService.stop('griddata');
        const error = err as HttpErrorResponse;
        this.dialogRef.close(error.error);
      },
    });
  }

  sendToSapApiCall() {
    const payload = {
      eventId: this.data.eventId,
    };
    this.ngxUiLoaderService.start('sendtosap');
    this.trialOrderService.sendToSap(payload)?.subscribe({
      next: (result) => {
        this.ngxUiLoaderService.stop('sendtosap');
        this.dialogRef.close(result);
      },
      error: (err) => {
        this.ngxUiLoaderService.stop('sendtosap');
        const error = err as HttpErrorResponse;
        this.dialogRef.close(error.error);
      },
    });
  }
}
