<div
  *ngIf="customizedSuccessMessage && customizedSuccessMessage.length !== 0"
  class="alert alert-success alert-dismissible fade show ms-4 me-4 p-2 mt-2"
  role="alert"
>
  <button
    type="button"
    class="btn-close"
    (click)="close()"
    data-bs-dismiss="alert"
    aria-label="close"
  ></button>
  <strong class="ps-2" *ngFor="let i of customizedSuccessMessage"
    >{{ i }}<br
  /></strong>
</div>
<div
  *ngIf="customizederrorMessage && customizederrorMessage?.length !== 0"
  class="alert alert-danger alert-dismissible ms-4 me-4 p-2 mt-2"
>
  <button
    type="button"
    class="btn-close"
    (click)="close()"
    data-bs-dismiss="alert"
    aria-label="close"
  ></button>
  <strong class="ps-2" *ngFor="let i of customizederrorMessage"
    >{{ i }}<br
  /></strong>
</div>

<app-notification-message
  [errorMessage]="errorMessage"
  [successMessage]="successMessage"
>
</app-notification-message>
<div class="d-flex justify-content-end">
  <button class="mt-3 me-2 action-btn">
    <fa-icon
      [icon]="faFilter"
      title="Filter Grid"
      class="d-flex justify-content-center"
    ></fa-icon>
  </button>

  <button class="mt-3 me-2 action-btn" (click)="viewTrails()">
    <fa-icon
      [icon]="faEye"
      title="View Single Order"
      class="d-flex justify-content-center"
    ></fa-icon>
  </button>

  <button class="mt-3 me-2 action-btn" (click)="openConfirmSingleOrder()">
    <fa-icon
      [icon]="faCheck"
      title="Confirm Single Order Supplier Ship Date"
      class="d-flex justify-content-center"
    ></fa-icon>
  </button>

  <button class="mt-3 me-2 action-btn" (click)="openConfirmDialog()">
    <fa-icon
      [icon]="faCheckSquareO"
      title="Confirm Selected Orders Supplier Ship Date"
    ></fa-icon>
  </button>

  <button class="mt-3 me-2 action-btn"  (click)="openTemplateECI()" *ngIf="eventId !== '-1'" style="width: 60px">ECI</button>

  <button
    class="mt-3 me-2 action-btn"
    *ngIf="eventDetailsFlags.isAllEvents === 'false'"
    (click)="openTemplateLabel()"
  >
    <fa-icon [icon]="faDownload" title="Download Labels Template"></fa-icon>
  </button>
  <button
    class="mt-3 me-2 action-btn"
    *ngIf="eventDetailsFlags.isAllEvents === 'false'"
    (click)="openTemplateShipping()"
  >
    <fa-icon
      [icon]="faTruck"
      class="d-flex justify-content-center"
      title="Download Shipping Template"
    ></fa-icon>
  </button>

  <button class="mt-3 me-2 action-btn">
    <fa-icon
      [icon]="faShareFromSquare"
      title="Export Orders"
      (click)="exportSupplierOrders()"
      (keydown)="exportSupplierOrders()"
    ></fa-icon>
  </button>

  <button
    class="mt-3 me-2 action-btn"
    *ngIf="
      eventDetailsFlags.isAllEvents === 'false' &&
      eventDetailsFlags.isEventDeleted === 'false'
    "
    (click)="supplierCommentsValidation()"
    (keydown)="supplierCommentsValidation()"
  >
    <fa-icon [icon]="faComment" title="Supplier Comments"></fa-icon>
  </button>

  <button
    class="mt-3 me-2 action-btn"
    *ngIf="
      eventDetailsFlags.isAllEvents === 'false' &&
      eventDetailsFlags.isEventDeleted === 'false'
    "
    (click)="openShipChangeDialog()"
  >
    <fa-icon
      [icon]="faWeibo"
      title="Supplier Ship Date Change Request"
    ></fa-icon>
  </button>
</div>
