<div class="add-new-message-container">
  <h2>Add New Message</h2>
  <hr />
  <form class="form-container">
    <div class="form-group">
      <div class="d-flex">
        <div class="vertical-bar"></div>
        <label for="supplierPickupDate">SUPPLIER PICKUP DATE</label>
      </div>
      <input
        type="date"
        id="supplierPickupDate"
        class="form-control"
        name="supplierPickupDate"
        [(ngModel)]="expirationDate"
      />
      <small class="text-danger" *ngIf="!MandatDate"
        >This is a mandatory Field</small
      >
    </div>

    <div class="form-group">
      <div class="d-flex">
        <div class="vertical-bar"></div>
        <label for="namcComment">NAMC COMMENT</label>
      </div>
      <textarea
        id="namcComment"
        class="form-control"
        name="namcComment"
        [(ngModel)]="namcComment"
      ></textarea>
      <small class="text-danger" *ngIf="!MandatComment"
        >This is a mandatory Field</small
      >
    </div>

    <div class="create_footer fixed-bottom">
      <button
        class="cancel me-3 mt-2 roboto-bold cancelbtnalign"
        (click)="cancel()"
      >
        CANCEL
      </button>
      <button class="black-button blackbtnalign me-3 mt-2" (click)="validateFields()">
        SUBMIT
      </button>
    </div>
  </form>
</div>
