import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
@Component({
  selector: 'app-supplier-ship-date',
  templateUrl: './supplier-ship-date.component.html',
  styleUrls: ['./supplier-ship-date.component.scss'],
})
export class SupplierShipDateComponent {
  namcComments!: string;
  namcProvidedShipDate!: string;
  mandateDate = '';

  constructor(
    private readonly dialogRef: MatDialogRef<SupplierShipDateComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: string
  ) {}

  closeDialog(): void {
    this.dialogRef.close({ status: false, result: {} });
  }

  onValidateAndSave() {
    if (!this.namcProvidedShipDate) {
      this.mandateDate = 'Please enter a valid date';
      setTimeout(() => {
        this.mandateDate = '';
      }, 3000);
      return;
    }
    this.namcProvidedShipDate = this.convertDate(this.namcProvidedShipDate)
    this.submitForm();
  }

  convertDate(dateStr: string) {
    const [year, month, day] = dateStr.split('-');
    return `${month}/${day}/${year}`;
  }

  submitForm() {
    if (this.data === 'FromNamc') {
      this.dialogRef.close({
        status: true,
        result: {
          namcProvidedShipDate: this.namcProvidedShipDate ?? '',
          namcComments: this.namcComments ?? '',
        },
      });
    } else if (this.data === 'fromSupplier') {
      this.dialogRef.close({
        status: true,
        result: {
          supplierRqstedDate: this.namcProvidedShipDate ?? '',
          supplierRequestedComment: this.namcComments ?? '',
        },
      });
    }
  }
}
