import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { TrialOrdersService } from 'src/app/services/event-and-orders/trial-orders.service';

@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss'],
})
export class FiltersComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<FiltersComponent>,
    private readonly valueService: TrialOrdersService
  ) {}
  faXmark = faXmark;

  cities = [
    {
      value: 'ORDER STATUS',
      label: 'ORDER STATUS',
    },
    {
      value: 'ORDER TRACKING NO',
      label: 'ORDER TRACKING NO',
    },
    {
      value: 'ORDER TYPE',
      label: 'ORDER TYPE',
    },
    {
      value: 'PO Tracking No',
      label: 'PO Tracking No',
    },
    {
      value: 'SAP Status',
      label: 'SAP Status',
    },
    {
      value: 'EVENT NAME',
      label: 'EVENT NAME',
    },
    {
      value: 'PROJECT',
      label: 'PROJECT',
    },
    {
      value: 'PSMS ROUTING',
      label: 'PSMS ROUTING',
    },
    {
      value: 'SPECIALIST NAME',
      label: 'SPECIALIST NAME',
    },
    {
      value: 'SUPPLIER CODE',
      label: 'SUPPLIER CODE',
    },
    {
      value: 'KANBAN NUMBER',
      label: 'KANBAN NUMBER',
    },
    {
      value: 'PART NUMBER',
      label: 'PART NUMBER',
    },
    {
      value: 'PART NAME',
      label: 'PART NAME',
    },
    {
      value: 'REVISION',
      label: 'REVISION',
    },
    {
      value: 'NAMC DUE DATE',
      label: 'NAMC DUE DATE',
    },
    {
      value: 'SUPPLIER PICKUP DATE',
      label: 'SUPPLIER PICKUP DATE',
    },
    {
      value: 'SUPPLIER SHIP DATE',
      label: 'SUPPLIER SHIP DATE',
    },
    {
      value: 'TOTAL QTY',
      label: 'TOTAL QTY',
    },
    {
      value: 'SUPPLIER NAME',
      label: 'SUPPLIER NAME',
    },
    {
      value: 'NAMC COMMENT',
      label: 'NAMC COMMENT',
    },
    {
      value: 'SUPPLIER COMMENT',
      label: 'SUPPLIER COMMENT',
    },
    {
      value: 'STORE ADDRESS',
      label: 'STORE ADDRESS',
    },
    {
      value: 'LINE SIDE ADDRESS',
      label: 'LINE SIDE ADDRESS',
    },
    {
      value: 'DOCK CODE',
      label: 'DOCK CODE',
    },
    {
      value: 'PALLET CODE',
      label: 'PALLET CODE',
    },
    {
      value: 'MANIFEST NUMBER',
      label: 'MANIFEST NUMBER',
    },
    {
      value: 'RECEIVER NUMBER',
      label: 'RECEIVER NUMBER',
    },
    {
      value: 'NA PIPE STATUS',
      label: 'NA PIPE STATUS',
    },
    {
      value: 'ECI NUMBER',
      label: 'ECI NUMBER',
    },
    {
      value: 'ECI REVISION',
      label: 'ECI REVISION',
    },
    {
      value: 'SPECIALIST CODE',
      label: 'SPECIALIST CODE',
    },
    {
      value: 'SHIPPING DATE',
      label: 'SHIPPING DATE',
    },
    {
      value: 'TRAILER NUMBER',
      label: 'TRAILER NUMBER',
    },
    {
      value: 'SHIPPING CARRIER',
      label: 'SHIPPING CARRIER',
    },
    {
      value: 'TRACKING/AIRLBILL NO',
      label: 'TRACKING/AIRLBILL NO',
    },
    {
      value: 'LIFE CYCLE CODE',
      label: 'LIFE CYCLE CODE',
    },
    {
      value: 'WORK ORDER NUMBER',
      label: 'WORK ORDER NUMBER',
    },
    {
      value: 'SUPPLIER SHIP DATE CHANGE REQUEST',
      label: 'SUPPLIER SHIP DATE CHANGE REQUEST',
    },
    {
      value: 'SUPPLIER SHIP DATE CHANGE COMMENT',
      label: 'SUPPLIER SHIP DATE CHANGE COMMENT',
    },
    {
      value: 'OWK LABEL LINE1',
      label: 'OWK LABEL LINE1',
    },
    {
      value: 'OWK LABEL LINE2',
      label: 'OWK LABEL LINE2',
    },
    {
      value: 'SCAN COMMENT',
      label: 'SCAN COMMENT',
    },
    {
      value: 'SEND TO SAP FLAG',
      label: 'SEND TO SAP FLAG',
    },
    {
      value: 'PO LINE NUMBER',
      label: 'PO LINE NUMBER',
    },
    {
      value: 'ARIBA PR NO',
      label: 'ARIBA PR NO',
    },
    {
      value: 'ARIBA ORDER STATUS',
      label: 'ARIBA ORDER STATUS',
    },
    {
      value: 'ARIBA ORDER COMMENT',
      label: 'ARIBA ORDER COMMENT',
    },
    {
      value: 'SAP SUBMISSION COMMENT',
      label: 'SAP SUBMISSION COMMENT',
    },
  ];

  selectedCities: { label: string; value: string }[] = [];

  chipClicked(chip: string) {
    this.selectedCities = this.selectedCities.filter(
      (item) => item.label !== chip
    );
  }

  selectAll(): void {
    this.selectedCities = [...this.cities];
  }

  clearAll(): void {
    this.selectedCities = [];
  }

  onSave() {
    this.valueService.emitValue(this.selectedCities);
    this.dialogRef.close();
  }

  ngOnInit(): void {
    const savedColumns = JSON.parse(
      localStorage.getItem('columns-saved') ?? '[]'
    );

    if (savedColumns.length === 0) {
      this.selectedCities = [...this.cities];
    } else {
      this.selectedCities = savedColumns;
    }

    this.valueService.value$?.subscribe(
      (value: { label: string; value: string }[]) => {
        this.selectedCities = value;
        if (savedColumns.length === 0) {
          this.cities = value;
        }
      }
    );
  }

  closeDialog() {
    this.dialogRef?.close();
  }
}
