<div class="popup-container">
  <button class="close black-button" (click)="closeDialog()">
    <mat-icon>close</mat-icon>
  </button>

  <h2 class="p-3 mb-0 roboto-medium">Supplier Ship Date Change Request</h2>

  <div class="modal-footer">
    <button mat-button class="yes-button" (click)="onAcceptClick()">
      Accept
    </button>
    <button mat-button class="no-button" (click)="onRejectClick()">
      Reject
    </button>
  </div>
  <div class="dialog-body">
    <p>
      "Are you sure you want to update Supplier Ship Date?" Enter Date
      (MM/DD/YYYY) below.
    </p>
    <input type="text" [disabled]="isDisabled" [(ngModel)]="EntryDate" />
    <small *ngIf="validDateMsg2.length > 0" class="text-danger">{{
      validDateMsg2
    }}</small>
    <br />
    <p>NAMC Due Date</p>
    <input type="text" [disabled]="isDisabled" [(ngModel)]="NamcDueDate" />
    <small *ngIf="validDateMsg1.length > 0" class="text-danger">{{
      validDateMsg1
    }}</small>
    <br />
    <p>Supplier Ship Date change Request Comments</p>
    <textarea
      placeholder=""
      [disabled]="true"
      [(ngModel)]="SupplierComments"
    ></textarea>
    <br />
    <p>Namc Comments</p>
    <textarea placeholder="" [(ngModel)]="NamcComments"></textarea>
  </div>
  <div class="dialog-actions">
    <button
      class="yes-button"
      [disabled]="isDisabled"
      [ngClass]="{ disabled: isDisabled }"
      (click)="onYesClick()"
    >
      SAVE
    </button>
    <button
      mat-button
      class="no-button"
      [disabled]="isDisabled"
      (click)="closeDialog()"
    >
      CANCEL
    </button>
  </div>
</div>
