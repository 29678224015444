import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { faWeibo } from '@fortawesome/free-brands-svg-icons';
import { faCheckSquare } from '@fortawesome/free-regular-svg-icons';
import {
  faCheck,
  faComment,
  faDownload,
  faEye,
  faFilter,
  faShareFromSquare,
  faTruck,
} from '@fortawesome/free-solid-svg-icons';
import * as ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import { ConfirmSelectedOrdersComponent } from 'src/app/core/dialogs/event-and-orders/confirm-selected-orders/confirm-selected-orders.component';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { SupplierShipDateComponent } from 'src/app/core/dialogs/event-and-orders/supplier-ship-date/supplier-ship-date.component';
import { SupplierCommentsActionComponent } from 'src/app/core/dialogs/supplier-comments-action/supplier-comments-action.component';
import { SupplierCommentsActionList } from 'src/app/core/model/comments.model';
import {
  AttributeDetails,
  EciApiResponse,
  ExcelHeader,
  LabelApiResponse,
  ShippingApiResponse,
  TrialOrder,
  TrialOrderDatas,
  TrialOrderDetails,
  TrialOrderdata,
} from 'src/app/core/model/trial-order.model';
import { User } from 'src/app/core/model/user.model';
import { ExportOrdersService } from 'src/app/services/event-and-orders/export-orders.service';
import { TrialOrdersService } from 'src/app/services/event-and-orders/trial-orders.service';
import { UserService } from 'src/app/services/user.service';
import { SelectedRole } from 'src/app/core/model/role-check.model';

@Component({
  selector: 'app-supplier-trial-order-buttons',
  templateUrl: './supplier-trial-order-buttons.component.html',
  styleUrls: ['./supplier-trial-order-buttons.component.scss'],
})
export class SupplierTrialOrderButtonsComponent implements OnInit {
  customizedSuccessMessage: string[] = [];
  customizederrorMessage: string[] = [];
  successMessage: string | undefined = undefined;
  errorMessage: string | undefined = undefined;
  faComment = faComment;
  faCheckSquareO = faCheckSquare;
  faEye = faEye;
  faWeibo = faWeibo;
  faFilter = faFilter;
  faCheck = faCheck;
  faDownload = faDownload;
  faTruck = faTruck;
  faShareFromSquare = faShareFromSquare;
  @Input() currentRole = '';
  @Input() eventId = '';
  @Input() eventDetailsFlags: AttributeDetails = {
    trialEventName: '',
    displayPOTrackingNumber: '',
    showEdit: '',
    isAllEvents: '',
    isEventDeleted: '',
  };

  unChangeMessage = 'From Trial Order, do NOT change this field.';
  supShipDateMessage = 'MM/DD/YYYY format, Date parts are due at NAMC. Do NOT change this field.';
  supPickupDateMessage = 'MM/DD/YYYY format, Date parts are planned to be picked up at supplier. Do NOT change this field.';
  labelRequestMessage = 'From Label Request, do NOT change this field.';
  bufferType = 'application/octet-stream';
  trialOrderUnchangeMessage = 'From Trial Order, do NOT change this field.';
  orderId!: string;
  @Output() supplierCommentEmit: EventEmitter<SupplierCommentsActionList> =
    new EventEmitter<SupplierCommentsActionList>();
  @Input() selectedOrder: TrialOrderDetails[] = [];
  limit = 4200;
  @Input() rowCount!: number;
  trialOrderDetails: TrialOrderDetails[] = [];
  start = 1;
  userId!: string;
  selectedRoles: SelectedRole | null = null;
  constructor(
    private readonly exportService: ExportOrdersService,
    private readonly trialOrderService: TrialOrdersService,
    public dialog: MatDialog,
    private readonly ngxUiLoaderService: NgxUiLoaderService,
    private readonly eciService: TrialOrdersService,
    private readonly userService: UserService,
    public router: Router
  ) { }

  ngOnInit(): void {
    this.userService.getUserDetails()?.subscribe((response: User) => {
      this.userId = response.userId;

    });
    this.userService.selectedRoles?.subscribe((selectedRoles) => {
      this.selectedRoles = selectedRoles;
      console.log(this.selectedRoles?.supplierCode)
    });
  }

  openConfirmSingleOrder() {
    console.log('confirm selected orders')
    if (this.selectedOrder.length === 1) {
      const data = JSON.stringify(this.selectedOrder[0]);
      this.router.navigate(['event-order/trial-orders/confirmSupplierOrder'], {
        state: { data: data },
      });
    } else if (this.selectedOrder.length === 0 || !this.selectedOrder) {
      const err = 'YOU NEED TO SELECT EXACTLY ONE ORDER';
      const currentPath = this.router.routerState.snapshot.url;
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
        this.router.navigate([currentPath], {
          state: { err, myData: this.eventId, dummy: true },
        });
      });
    } else {
      const err = 'YOU NEED TO SELECT ONE ORDER FOR THE SELECTED OPERATION';
      const currentPath = this.router.routerState.snapshot.url;
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
        this.router.navigate([currentPath], {
          state: { err, myData: this.eventId, dummy: true },
        });
      });
    }
  }

  openConfirmDialog() {
    const dialogRef = this.dialog.open(ConfirmSelectedOrdersComponent, {
      width: '400px',
      data: {
        selectedOrder: this.selectedOrder,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.supplierCommentEmit.emit({ message: '', success: true });
      this.customizederrorMessage =
        Object.keys(result).length > 1 ? result.error : Object.values(result);
      this.customizedSuccessMessage = result.success ?? [];
      setTimeout(() => {
        this.customizederrorMessage.length = 0;
        this.customizedSuccessMessage.length = 0;
      }, 7000);
    });
  }

  close() {
    this.customizedSuccessMessage.length = 0;
    this.customizederrorMessage.length = 0;
  }

  supplierCommentsValidation() {
    if (this.selectedOrder.length === 0) {
      this.errorMessage = 'Please select at least one order.';
      this.settingTimeOut();
    } else if (this.selectedOrder.length > 1) {
      this.errorMessage = 'Please select only one order';
      this.settingTimeOut();
    } else if (this.selectedOrder[0].status.toLowerCase() === 'cancelled') {
      this.errorMessage =
        'Supplier Comment cannot be updated for Order Status Cancelled.';
      this.settingTimeOut();
    } else {
      this.orderId = this.selectedOrder[0].orderNumber;
      this.openSupplierComments(this.selectedOrder);
    }
  }

  openSupplierComments(selectedOrder: TrialOrderDetails[]) {
    this.successMessage = undefined;
    this.errorMessage = undefined;
    const dialogRef = this.dialog.open(SupplierCommentsActionComponent, {
      disableClose: true,
      width: '570px',
      height: '309px',
      data: {
        selectedOrder,
      },
    });

    dialogRef.afterClosed()?.subscribe((result: string | undefined) => {
      if (result === undefined) {
        this.selectedOrder = [];
        return;
      }
      if (result === 'Success') {
        this.supplierCommentEmit.emit({
          success: true,
          message: `Supplier Comment for '${this.orderId}' Updated successfully.`,
        });
      } else {
        this.supplierCommentEmit.emit({
          success: false,
          message: `Supplier Comment for '${this.orderId}' failed to update.`,
        });
      }
      this.selectedOrder = [];
    });
  }

  exportSupplierOrders() {
    this.ngxUiLoaderService.start('getTrialOrdersData');
    const control: number[] = [];
    for (let i = 1; i <= Math.ceil(this.rowCount / 4200); i++) {
      control.push(1);
      this.trialOrderService
        .getGridData(this.eventId, this.start, this.limit)
        ?.subscribe({
          next: (result) => {
            this.trialOrderDetails = [
              ...this.trialOrderDetails,
              ...result.trialOrderData,
            ];
            control.pop();
            if (control.length === 0) {
              this.ngxUiLoaderService.stop('getTrialOrdersData');
              this.exportService.setExportOrderDetails(
                this.trialOrderDetails,
                this.currentRole,
                this.eventDetailsFlags.displayPOTrackingNumber,
                this.eventId
              );
            }
          },
          error: (err) => {
            this.ngxUiLoaderService.stop('getTrialOrdersData');
            const error = err as HttpErrorResponse;
            this.errorMessage = error.error.error;
            this.settingTimeOut();
          },
        });
      this.start = this.limit + 1;
      this.limit = this.limit + 4200;
    }
  }

  settingTimeOut() {
    setTimeout(() => {
      this.errorMessage = undefined;
      this.successMessage = undefined;
    }, 5000);
  }
  openTemplateShipping() {

    this.eciService.downloadShippingFile(Number(this.eventId)).subscribe({
      next: (result: ShippingApiResponse): void => {
        console.log(result)
        const excelHeaders: ExcelHeader[] = result.excelHeaders;
        const trialOrderData: TrialOrderdata[] = result.trialOrderData;

        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('Sheet1');
        const headers = excelHeaders.map((header) => header.attrib_val_txt);
        worksheet.addRow(headers);
        const headerNotesMap: { [key: string]: string } = {
          'ORDER TRACKING NO': this.unChangeMessage,
          'SUPPLIER  CODE': this.unChangeMessage,
          'KANBAN NO': this.unChangeMessage,
          'PART NO': this.unChangeMessage,
          'PART NAME': this.unChangeMessage,
          'NAMC DUE DATE':
            this.supShipDateMessage,
          'SUP PICKUP DATE':
            this.supPickupDateMessage,
          'SUP SHIP DATE':
            this.supShipDateMessage,
          'TRIALQTY': this.unChangeMessage,
          'CFQTY': this.unChangeMessage,
          'TOTAL QTY': this.unChangeMessage,
          'NUMBER OF FULL BOXES':
            this.labelRequestMessage,
          'QTY PER FULL BOX': this.labelRequestMessage,
          'NUMBER OF PARTIAL BOXES':
            this.labelRequestMessage,
          'QTY PER PARTIAL BOX':
            this.labelRequestMessage,
          'TOTAL NUMBER OF BOXES':
            this.labelRequestMessage,
          'ECI NUMBER': 'From Label Request,do NOT change this field.',
          'ECI REVISION': 'From Label Request,do NOT change this field.',
          'SHIPPING DATE':
            'MM/DD/YYYY format, Date parts physically shipped from Supplier.',
          'TRAILER NUMBER':
            'Trailer Number up to 10 characters, enter trailer number from pickup, no dashes.',
          'SHIPPING CARRIER':
            'Enter Carrier Name trial parts were picked up by.',
          'IS SHIPPING DETAIL PROVIDED':
            'Answer YES or NO.YES means you are ready to make the Shiping Details.NO means you are not ready,but will submit at a later date.',
          'TRACKING/AIRBILL NUMBER':
            'Optional upto 30 characters,enter Tracking Number if shipping by UPS/Fed EX/USPS,or Airbill number if shipping by Air.',
        };
        const headerToKeyMap: { [key: string]: keyof TrialOrderdata | 'qtyValues' } = {
          'ORDER TRACKING NO': 'orderNumber',
          'SUPPLIER  CODE': 'supplierCd',
          'KANBAN NO': 'kanbanNumber',
          'PART NO': 'partNumber',
          'PART NAME': 'partName',
          'NAMC DUE DATE': 'supplierPickupDate',
          'SUP PICKUP DATE': 'supplierPickupDate',
          'SUP SHIP DATE': 'supplierShippingDate',
          'TOTAL QTY': 'totalQty',
          'TRIALQTY': 'qtyValues',
          'ASSYTRAINQTY': 'qtyValues',
          'CFQTY': 'qtyValues',
          'PEQTY': 'qtyValues',
          'QEQTY': 'qtyValues',
          'AXWQTY': 'qtyValues',
          'NUMBER OF FULL BOXES': 'nofullBoxes',
          'QTY PER FULL BOX': 'qtyperFullbox',
          'NUMBER OF PARTIAL BOXES': 'noPartialBoxes',
          'QTY PER PARTIAL BOX': 'qtyperPartialbox',
          'TOTAL NUMBER OF BOXES': 'totalnoBoxes',
          'ECI NUMBER': 'eciNumber',
          'ECI REVISION': 'eciRevision',
          'SHIPPING DATE': 'shippingDate',
          'TRAILER NUMBER': 'trailerNumber',
          'SHIPPING CARRIER': 'shippingCarrier',
          'IS SHIPPING DETAIL PROVIDED': 'isShippingDetailProvided',
          'TRACKING/AIRBILL NUMBER': 'trackingairbillNumber',
        };
        trialOrderData.forEach((order) => {
          const rowData = excelHeaders.map((header) => {
            const key = headerToKeyMap[header.attrib_val_txt];

            if (key === 'qtyValues') {
              const qtyItem = order.quantityValues.find((item: any) => item.quantityName === header.attrib_val_txt);
              return qtyItem ? qtyItem.orderQuantity : '';
            }

            return key ? order[key] : '';
          });
          worksheet.addRow(rowData);
        });

        excelHeaders.forEach((header, index) => {
          const cell = worksheet.getCell(1, index + 1);
          cell.value = header.attrib_val_txt;

          cell.font = { bold: true, size: 10 };
          cell.alignment = { vertical: 'middle', horizontal: 'center' };

          const note = headerNotesMap[header.attrib_val_txt] || this.unChangeMessage;
          cell.note = note;
          if (index < headers.indexOf('SHIPPING DATE')) {
            cell.fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: 'FFC0C0C0' },
            };
          }
          worksheet.eachRow((row, rowIndex) => {
            if (rowIndex > 1) {
              const dataCell = row.getCell(index + 1);
              if (index < headers.indexOf('SHIPPING DATE')) {
                dataCell.fill = {
                  type: 'pattern',
                  pattern: 'solid',
                  fgColor: { argb: 'FFC0C0C0' }
                };
              } 

              dataCell.border = {
                top: { style: 'thin', color: { argb: 'FFC0C0C0' } },
                left: { style: 'thin', color: { argb: 'FFC0C0C0' } },
                bottom: { style: 'thin', color: { argb: 'FFC0C0C0' } },
                right: { style: 'thin', color: { argb: 'FFC0C0C0' } }
              };

              dataCell.alignment = { vertical: 'middle', horizontal: 'center' };
            }
          });
        });
        workbook.xlsx.writeBuffer().then((buffer) => {
          const blob = new Blob([buffer], { type: this.bufferType });
          const currentDate = new Date()
            .toISOString()
            .slice(0, 10)
            .replace(/-/g, '');
          const currentTime = new Date()
            .toISOString()
            .slice(11, 16)
            .replace(/:/g, '');
          const fileName = `ShippingTemplate_${this.eventId}_${currentDate}_${currentTime}.xlsx`;
          saveAs(blob, fileName);
        });
      },
    });
  }

  openTemplateLabel() {

    this.eciService.downloadLabelFile(Number(this.eventId)).subscribe({
      next: (result: LabelApiResponse): void => {
        console.log(result)
        const excelHeaders: ExcelHeader[] = result.excelHeaders;
        const trialOrderData: TrialOrder[] = result.trialOrderData;

        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('Sheet1');
        const headers = excelHeaders.map((header) => header.attrib_val_txt);
        worksheet.addRow(headers);
        const headerNotesMap: { [key: string]: string } = {
          'ORDER TRACKING NO': this.unChangeMessage,
          'SUPPLIER  CODE': this.unChangeMessage,
          'KANBAN NO': this.unChangeMessage,
          'PART NO': this.unChangeMessage,
          'PART NAME': this.unChangeMessage,
          'NAMC DUE DATE':
            this.supShipDateMessage,
          'SUP PICKUP DATE':
            this.supPickupDateMessage,
          'SUP SHIP DATE':
            'MM/DD/YYYY format, Date parts agreed are due to ship from Supplier. Do NOT change this field.',
          'TRIALQTY': this.unChangeMessage,
          'ASSYTRAINQTY': this.unChangeMessage,
          'VUTEX QTY': this.unChangeMessage,
          'AXWQTY': this.unChangeMessage,
          'TOTAL QTY': this.unChangeMessage,
          'NUMBER OF FULL BOXES':
            'Required, Enter the number of Full boxes requested only.',
          'QTY PER FULL BOX':
            'Required, Enter the QPC per container for full boxes.',
          'NUMBER OF PARTIAL BOXES':
            'Optional, Enter the No of Partial boxes requested only (Should only be one box, unless splitting shipments).',
          'QTY PER PARTIAL BOX':
            'Optional, Enter the QPC per container for partial boxes.',
          'TOTAL NUMBER OF BOXES':
            'Auto calculated, Enter the total number of boxes, both full and partial being requested.',
          'IS LABEL DETAIL PROVIDED':
            'ready to make the Label Request to Print.  NO means you are not ready, but will submit at a later date.',
        };
        const headerToKeyMap: { [key: string]: keyof TrialOrder | 'qtyValues' } = {
          'ORDER TRACKING NO': 'orderNumber',
          'SUPPLIER  CODE': 'supplierCd',
          'KANBAN NO': 'kanbanNumber',
          'PART NO': 'partNumber',
          'PART NAME': 'partName',
          'NAMC DUE DATE': 'namcDueDate',
          'SUP PICKUP DATE': 'supplierPickupDate',
          'SUP SHIP DATE': 'supplierShippingDate',
          'TRIALQTY': 'qtyValues',
          'ECI Test1': 'qtyValues',
          'ECII Test2': 'qtyValues',
          'TOTAL QTY': 'totalQty',
          'NUMBER OF FULL BOXES': 'nofullBoxes',
          'QTY PER FULL BOX': 'qtyperFullbox',
          'NUMBER OF PARTIAL BOXES': 'noPartialBoxes',
          'QTY PER PARTIAL BOX': 'qtyperPartialbox',
          'TOTAL NUMBER OF BOXES': 'totalnoBoxes',
          'IS LABEL DETAIL PROVIDED': 'isLabelDetailProvided',

        };
        trialOrderData.forEach((order) => {
          const rowData = excelHeaders.map((header) => {
            const key = headerToKeyMap[header.attrib_val_txt];

            if (key === 'qtyValues') {

              const qtyItem = order.quantityData.find((item: any) => item.qtyName === header.attrib_val_txt);
              return qtyItem ? qtyItem.orderQty : '';
            }

            return key ? order[key] : '';
          });
          worksheet.addRow(rowData);
        });
        excelHeaders.forEach((header, index) => {
          const cell = worksheet.getCell(1, index + 1);
          cell.value = header.attrib_val_txt;

          cell.font = { bold: true, size: 10 };
          cell.alignment = { vertical: 'middle', horizontal: 'center' };

          const note = headerNotesMap[header.attrib_val_txt] || this.unChangeMessage;
          cell.note = note;
          if (index <= headers.indexOf('TOTAL QTY')) {
            cell.fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: 'FFC0C0C0' },
            };
          }
          worksheet.eachRow((row, rowIndex) => {
            if (rowIndex > 1) {
              const dataCell = row.getCell(index + 1);
              if (index <= headers.indexOf('TOTAL QTY')) {
                dataCell.fill = {
                  type: 'pattern',
                  pattern: 'solid',
                  fgColor: { argb: 'FFBFBFBF' }
                };
              } 
              dataCell.border = {
                top: { style: 'thin', color: { argb: 'FFBFBFBF' } },
                left: { style: 'thin', color: { argb: 'FFBFBFBF' } },
                bottom: { style: 'thin', color: { argb: 'FFBFBFBF' } },
                right: { style: 'thin', color: { argb: 'FFBFBFBF' } }
              };
              dataCell.alignment = { vertical: 'middle', horizontal: 'center' };
            }
          });
      
        });
        workbook.xlsx.writeBuffer().then((buffer) => {
          const blob = new Blob([buffer], { type: this.bufferType });
          const currentDate = new Date()
            .toISOString()
            .slice(0, 10)
            .replace(/-/g, '');
          const currentTime = new Date()
            .toISOString()
            .slice(11, 16)
            .replace(/:/g, '');
          const fileName = `LabelRequestTemplate_${this.eventId}_${currentDate}_${currentTime}.xlsx`;
          saveAs(blob, fileName);
        });
      },
    });
  }
  openTemplateECI() {
    this.eciService.downloadEciFile(Number(this.eventId)).subscribe({
      next: (result: EciApiResponse): void => {
        console.log(result)
        const excelHeaders: ExcelHeader[] = result.excelHeaders;
        const trialOrderData: TrialOrderDatas[] = result.trialOrderData;

        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('Sheet1');

        const headers = excelHeaders.map((header) => header.attrib_val_txt);
        worksheet.addRow(headers);

        const headerNotesMap: { [key: string]: string } = {
          'ORDER TRACKING NO': this.unChangeMessage,
          'SUPPLIER  CODE': this.unChangeMessage,
          'KANBAN NO': this.unChangeMessage,
          'PART NO': this.unChangeMessage,
          'PART NAME': this.unChangeMessage,
          'NAMC DUE DATE': this.supShipDateMessage,
          'SUP PICKUP DATE': this.supPickupDateMessage,
          'SUP SHIP DATE': this.supShipDateMessage,
          'TRIALQTY': this.unChangeMessage,
          'ASSYTRAINQTY': this.unChangeMessage,
          'CFQTY': this.unChangeMessage,
          'PEQTY': this.unChangeMessage,
          'QEQTY ': this.unChangeMessage,
          'AXWQTY': this.unChangeMessage,
          'TOTAL QTY': this.unChangeMessage,
          'ECI NUMBER': 'Required, ECI Number is 9 digits.',
          'ECI REVISION': 'Required, ECI Revision is one or two digits, 0 is not accepted.',
        };

        const headerToKeyMap: { [key: string]: keyof TrialOrderDatas | 'qtyValues' } = {
          'ORDER TRACKING NO': 'orderNumber',
          'SUPPLIER  CODE': 'supplierCd',
          'KANBAN NO': 'kanbanNumber',
          'PART NO': 'partNumber',
          'PART NAME': 'partName',
          'NAMC DUE DATE': 'orderDueDate',
          'SUP PICKUP DATE': 'supplierPickupDate',
          'SUP SHIP DATE': 'promiseDate',
          'TOTAL QTY': 'totalQty',
          'TRIALQTY': 'qtyValues',
          'ASSYTRAINQTY': 'qtyValues',
          'CFQTY': 'qtyValues',
          'PEQTY': 'qtyValues',
          'QEQTY': 'qtyValues',
          'AXWQTY': 'qtyValues',
          'ECI NUMBER': 'eciNumber',
          'ECI REVISION': 'eciRevision',
        };
        trialOrderData.forEach((order) => {
          const rowData = excelHeaders.map((header) => {
            const key = headerToKeyMap[header.attrib_val_txt];

            if (key === 'qtyValues') {

              const qtyItem = order.qtyValues.find((item: any) => item.qtyName === header.attrib_val_txt);
              return qtyItem ? qtyItem.qtyOrder : '';
            }

            return key ? order[key] : '';
          });
          worksheet.addRow(rowData);
        });

        excelHeaders.forEach((header, colIndex) => {
          const headerCell = worksheet.getCell(1, colIndex + 1);
          headerCell.value = header.attrib_val_txt;
          headerCell.font = { bold: true, size: 10 };
          headerCell.alignment = { vertical: 'middle', horizontal: 'center' };
          if (header.attrib_val_txt === 'ECI NUMBER' || header.attrib_val_txt === 'ECI REVISION') {
            headerCell.fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: 'FFFFFFFF' }
            };
          } else {
            headerCell.fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: 'FFBFBFBF' }
            };
          }

          const note = headerNotesMap[header.attrib_val_txt] || this.unChangeMessage;
          headerCell.note = note;
          worksheet.eachRow((row, rowIndex) => {
            if (rowIndex > 1) {
              const dataCell = row.getCell(colIndex + 1);
              if (header.attrib_val_txt === 'ECI NUMBER' || header.attrib_val_txt === 'ECI REVISION') {
                dataCell.fill = {
                  type: 'pattern',
                  pattern: 'solid',
                  fgColor: { argb: 'FFFFFFFF' }
                };
              } else {
                dataCell.fill = {
                  type: 'pattern',
                  pattern: 'solid',
                  fgColor: { argb: 'FFBFBFBF' }
                };
              }
              dataCell.border = {
                top: { style: 'thin', color: { argb: 'FFBFBFBF' } },
                left: { style: 'thin', color: { argb: 'FFBFBFBF' } },
                bottom: { style: 'thin', color: { argb: 'FFBFBFBF' } },
                right: { style: 'thin', color: { argb: 'FFBFBFBF' } }
              };
              dataCell.alignment = { vertical: 'middle', horizontal: 'center' };
            }
          });
        });

        workbook.xlsx.writeBuffer().then((buffer) => {
          const blob = new Blob([buffer], { type: this.bufferType });
          const currentDate = new Date()
            .toISOString()
            .slice(0, 10)
            .replace(/-/g, '');
          const currentTime = new Date()
            .toISOString()
            .slice(11, 16)
            .replace(/:/g, '');
          const fileName = `EciDataTemplate_${this.eventId}_${currentDate}_${currentTime}.xlsx`;
          saveAs(blob, fileName);
        });
      },
    });
  }

  openShipChangeDialog() {
    if (this.selectedOrder.length === 0) {
      this.supplierCommentEmit.emit({
        success: false,
        message: `Please, Select Atleast One Order`,
      });
      return;
    }
    const dialogRef = this.dialog.open(SupplierShipDateComponent, {
      width: '400px',
      data: 'fromSupplier',
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result.status) {
        const payloadObj = this.getConstructedPayloadShippingDate(
          result.result
        );
        this.ngxUiLoaderService.start('Loader-01');
        this.trialOrderService.sendSupplierSendRequest(payloadObj).subscribe({
          next: (res: { result: string; errorList: string }) => {
            this.ngxUiLoaderService.stop('Loader-01');
            if (res.result === 'Success') {
              this.supplierCommentEmit.emit({
                success: true,
                message: `Orders Have Been Successfully Updated`,
              });
            }
            this.selectedOrder.length = 0;
          },
          error: (err) => {
            this.ngxUiLoaderService.stop('Loader-01');
            this.supplierCommentEmit.emit({
              success: false,
              message:
                err.status === 422
                  ? err.error.errorList.toString()
                  : 'Something Went Wrong, Please Contact Sytem Administrator',
            });
          },
        });
      }
    });
  }
  viewTrails() {
    if (this.selectedOrder.length === 1) {
      const data = JSON.stringify(this.selectedOrder[0]);
      this.router.navigate(
        ['event-order/trial-orders/trialOrderList/viewTrails'],
        {
          state: { mode: 'view', data: data },
        }
      );
    } else if (this.selectedOrder.length === 0 || !this.selectedOrder) {
      const err = 'YOU NEED TO SELECT EXACTLY ONE ORDER';
      const currentPath = this.router.routerState.snapshot.url;

      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
        this.router.navigate([currentPath], {
          state: { err, myData: this.eventId, dummy: true },
        });
      });
    } else {
      const err = 'YOU NEED TO SELECT ONE ORDER FOR THE SELECTED OPERATION';
      const currentPath = this.router.routerState.snapshot.url;

      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
        this.router.navigate([currentPath], {
          state: { err, myData: this.eventId, dummy: true },
        });
      });
    }
  }

  getConstructedPayloadShippingDate(result: {
    supplierRqstedDate: string;
    supplierRequestedComment: string;
  }) {
    return this.selectedOrder.map((e) => {
      return {
        appendShippingDateTime: 'defVal',
        appendOrderNumbers: e.orderNumber,
        appendEciNumber: 'defVal',
        appendEciRevision: 'defVal',
        userName: this.userId,
        appendTrailerNumber: 'defVal',
        appendShippingCarrier: 'defVal',
        appendComment: 'defVal',
        appendTrackingAirbillNumber: 'defVal',
        appendSuppShpDtChng: result.supplierRqstedDate,
        appendSuppShpDtCmmnt: result.supplierRequestedComment,
      };
    });
  }
}
