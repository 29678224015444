<app-notification-message
  [errorMessage]="errorMessage"
  [successMessage]="successMessage"
>
</app-notification-message>

<div class="manageUser-btn">
  <button
    class="me-3 b2-button add"
    (click)="collapseAll()"
    style="float: right"
  >
    {{ allPanelsCollapsed ? "Collapse All" : "Expand All" }}
  </button>
</div>

<mat-accordion hideToggle multi>
  <div class="mx-3 d-flex flex-column">
    <ng-container *ngFor="let category of documents">
      <mat-expansion-panel
        #panel
        *ngIf="category?.documentList?.length"
        [expanded]="true"
        class="m-2 rounded-0"
      >
        <mat-expansion-panel-header class="shadow-none m-0 border border-0">
          <mat-panel-title>
            {{ category.catnm | uppercase }}
          </mat-panel-title>
          <mat-icon class="toggleIcon fw-bolder" style="color: #707070;">{{
            panel.expanded ? "remove" : "add"
          }}</mat-icon>
        </mat-expansion-panel-header>
        <table
          mat-table
          [dataSource]="category.documentList"
          class="mat-elevation-z8 custom-table shadow-none border border-1"
        >
          <ng-container matColumnDef="document">
            <th
              mat-header-cell
              *matHeaderCellDef
              style="height: 40px"
              class="text-dark"
            >
              DOCUMENT
            </th>
            <td mat-cell *matCellDef="let doc">
              <div>
                <a (click)="downloadDocument(doc.docid, doc.docname)"
                  >{{ doc.doctitletext | uppercase }}
                </a>
              </div>
              <div class="d-flex">
                <b style="font-weight: 590">File : </b>
                <div style="font-weight: normal !important">
                  {{ doc.docname }}
                </div>
              </div>
              <div class="d-flex">
                <b style="font-weight: 590">Description : </b>
                <div style="font-weight: normal !important">
                  {{ doc.descriptiontext }}
                </div>
              </div>
            </td>
          </ng-container>

          <ng-container matColumnDef="date">
            <th mat-header-cell *matHeaderCellDef class="text-dark">DATE</th>
            <td mat-cell *matCellDef="let doc">
              {{ doc.updatetimestamp ?? doc.createtimestamp }}
            </td>
          </ng-container>

          <ng-container matColumnDef="fileSize">
            <th mat-header-cell *matHeaderCellDef class="text-dark">
              FILE SIZE
            </th>
            <td mat-cell *matCellDef="let doc">{{ doc.fileSize }}</td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="headers"></tr>
          <tr mat-row *matRowDef="let row; columns: headers"></tr>
        </table>
      </mat-expansion-panel>
    </ng-container>
  </div>
</mat-accordion>
