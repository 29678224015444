import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  faFastBackward,
  faFastForward,
  faStepBackward,
  faStepForward,
} from '@fortawesome/free-solid-svg-icons';
import {
  ColDef,
  ColumnApi,
  GridApi,
  GridOptions,
  GridReadyEvent,
  ModelUpdatedEvent,
  SelectionChangedEvent,
} from 'ag-grid-community';

@Component({
  selector: 'app-grid',
  templateUrl: './grid.component.html',
  styleUrls: ['./grid.component.scss'],
})
export class GridComponent implements OnInit, OnChanges {
  gridApi!: GridApi;
  @Input() context!: { componentParent: any };
  currentPageNo: number | undefined;
  currentPageBoolean = false;
  totalPage = 0;
  currentPageForTotalPage!: number;
  isLastPageFound!: boolean;
  paginationPageSize = 15;
  icons: {} | undefined;
  defaultColDef: {};
  rowHeight = 50;
  gridOptions: GridOptions = {
    enableCellTextSelection: true,
    domLayout: '' as unknown as 'autoHeight' | 'normal',
  };
  rowClassRules: {};
  @Input() columnDefs: ColDef[] = [];
  @Input() displayRowData: any[] = [];
  @Input() rowCount = 0;
  @Input() screen: string = '';
  @Output() select: EventEmitter<any[]> = new EventEmitter<any[]>();
  @Output() retainFilter: EventEmitter<ModelUpdatedEvent> =
    new EventEmitter<ModelUpdatedEvent>();
  @Output() pageLimit: EventEmitter<any> = new EventEmitter<any>();
  currentPageRowCount = 0;
  @Input() rowSelection: 'single' | 'multiple' | undefined = undefined;
  lbTotalPages = 0;
  lbCurrentPage = 0;
  faFastForward = faFastForward;
  faStepBackward = faStepBackward;
  faStepForward = faStepForward;
  faFastBackward = faFastBackward;
  gridColumnApi!: ColumnApi;
  totaldata = 0;
  startLimit = 1;
  endLimit = 15;
  pageNumber = 1;

  constructor() {
    this.rowClassRules = {
      'row-even': 'node.rowIndex % 2 === 0',
      'row-odd': 'node.rowIndex % 2 !== 0',
    };

    this.defaultColDef = {
      wrapHeaderText: true,
      autoHeight: true,
      filter: true,
      floatingFilter: true,
      resizable: true,
      filterParams: { newRowsAction: 'keep' },
    };
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.totaldata =
      this.screen === 'TrialOrder' ? this.rowCount : this.displayRowData.length;
  }

  ngOnInit(): void {
    this.icons = {
      sortUnSort: '<i class="fas fa-sort"></i>',
      sortAscending: '<i class="fas fa-sort-up"></i>',
      sortDescending: '<i class="fas fa-sort-down"></i>',
    };
  }

  onGridReady(params: GridReadyEvent) {
    this.gridApi = params.api;
    this.gridApi.sizeColumnsToFit();
    this.gridColumnApi = params.columnApi;
    this.gridApi.addEventListener('filterChanged', () => {
      const filterModel = this.gridApi.getFilterModel();
      Object.keys(filterModel).forEach((filterField) => {
        if (filterModel[filterField].filter) {
          filterModel[filterField].filter =
            filterModel[filterField].filter.trim();
        }
      });
      this.gridApi.setFilterModel(filterModel);
      this.updatePaginationData();
    });
  }

  onSelectionChanged(event: SelectionChangedEvent) {
    const selected: any[] = [];
    event.api.getSelectedNodes().forEach((value) => {
      selected.push(value.data);
    });
    this.select.emit(selected);
  }

  sizeToFit() {
    this.gridApi?.sizeColumnsToFit();
  }

  onModelUpdated(event: ModelUpdatedEvent): void {
    this.retainFilter.emit(event);
    this.sizeToFit();
  }

  goToNextpage() {
    if (this.screen !== 'TrialOrder') {
      this.gridApi.paginationGoToNextPage();
    } else {
      this.pageNumber++;
      this.startLimit = this.endLimit + 1;
      this.endLimit += this.paginationPageSize;
      this.endLimit =
        this.endLimit > this.totaldata ? this.totaldata : this.endLimit;
      this.pageLimit.emit({
        currentPage: this.startLimit,
        lastPage: this.endLimit,
      });
    }
  }
  goToPreviousPage() {
    if (this.screen !== 'TrialOrder') {
      this.gridApi.paginationGoToPreviousPage();
    } else {
      this.pageNumber--;
      this.endLimit = this.startLimit - 1;
      this.startLimit = this.startLimit - this.paginationPageSize;
      this.pageLimit.emit({
        currentPage: this.startLimit,
        lastPage: this.endLimit,
      });
    }
  }
  goToFirstPage() {
    if (this.screen !== 'TrialOrder') {
      this.gridApi.paginationGoToFirstPage();
    } else {
      this.pageNumber = 1;
      this.startLimit = 1;
      this.endLimit = this.paginationPageSize;
      this.pageLimit.emit({
        currentPage: this.startLimit,
        lastPage: this.endLimit,
      });
    }
  }
  goToLastPage() {
    if (this.screen !== 'TrialOrder') {
      this.gridApi.paginationGoToLastPage();
    } else {
      this.pageNumber = Math.round(this.totaldata / this.paginationPageSize);
      const remainingRecords = this.totaldata % this.paginationPageSize;
      this.startLimit = this.totaldata - remainingRecords + 1;
      this.endLimit = this.totaldata;
      this.pageLimit.emit({
        currentPage: this.startLimit,
        lastPage: this.endLimit,
      });
    }
  }
  getCurrentPage(): boolean {
    if (this.screen !== 'TrialOrder') {
      this.currentPageNo = this.gridApi?.paginationGetCurrentPage();

      if (this.currentPageNo === 0) {
        this.currentPageBoolean = true;
      } else {
        this.currentPageBoolean = false;
      }
    } else {
      this.currentPageBoolean = this.startLimit === 1;
    }
    return this.currentPageBoolean;
  }
  isLastPage(): boolean {
    if (this.screen !== 'TrialOrder') {
      this.totalPage = Number(this.gridApi?.paginationGetTotalPages());
      this.currentPageForTotalPage =
        Number(this.gridApi?.paginationGetCurrentPage()) + 1;
      if (this.totalPage === this.currentPageForTotalPage) {
        this.isLastPageFound = true;
      } else {
        this.isLastPageFound = false;
      }
    } else {
      this.isLastPageFound = this.endLimit === this.totaldata;
    }
    return this.isLastPageFound;
  }

  onPaginationChanged() {
    if (this.gridApi) {
      if (this.screen !== 'TrialOrder') {
        this.clientSidePaginationChange();
      } else {
        this.serverSidePaginationChange();
      }
    }
  }

  serverSidePaginationChange() {
    this.lbTotalPages =
      this.totaldata < this.endLimit ? this.totaldata : this.endLimit;
    if (this.totaldata < this.endLimit) {
      this.lbCurrentPage = this.startLimit;
      this.lbTotalPages = this.totaldata;
    } else if (this.endLimit === this.paginationPageSize) {
      this.lbCurrentPage = 1;
      this.lbTotalPages = this.paginationPageSize;
    } else {
      this.lbCurrentPage = this.startLimit;
      this.lbTotalPages = this.endLimit;
    }
    this.setLastButtonDisabled(!this.gridApi.paginationIsLastPageFound());
  }

  clientSidePaginationChange() {
    this.lbCurrentPage =
      this.displayRowData.length !== 0
        ? (this.gridApi.paginationGetCurrentPage() + 1 - 1) *
            this.paginationPageSize +
          1
        : 0;
    this.lbTotalPages =
      (this.gridApi.paginationGetCurrentPage() + 1) * this.paginationPageSize >
      this.displayRowData.length
        ? this.displayRowData.length
        : (this.gridApi.paginationGetCurrentPage() + 1) *
          this.paginationPageSize;
    this.setLastButtonDisabled(!this.gridApi.paginationIsLastPageFound());
  }

  onPageSizeChanged(event: Event) {
    console.log('next page', 'start=', this.startLimit, 'end=', this.endLimit);
    this.paginationPageSize = Number((event.target as HTMLInputElement).value);
    if (this.screen === 'TrialOrder') {
      const limitDiff = this.endLimit - this.startLimit + 1;
      if (limitDiff < this.paginationPageSize) {
        this.endLimit = this.paginationPageSize * this.pageNumber;
        this.startLimit = this.endLimit - this.paginationPageSize + 1;
      } else {
        this.endLimit =
          this.paginationPageSize > this.totaldata
            ? this.totaldata
            : this.paginationPageSize;
      }
      this.pageLimit.emit({
        currentPage: this.startLimit,
        lastPage: this.endLimit,
      });
    }
    this.gridApi?.paginationSetPageSize(
      Number((event.target as HTMLInputElement).value)
    );
  }

  setLastButtonDisabled(_disabled: boolean) {
    this.isLastPageFound = true;
  }

  updatePaginationData() {
    const filteredRowData = [];
    this.gridApi.forEachNodeAfterFilter((node) => {
      filteredRowData.push(node.data);
    });

    this.totaldata = filteredRowData.length;
    this.lbTotalPages = Math.ceil(this.totaldata / this.paginationPageSize);
    this.lbCurrentPage =
      (this.gridApi.paginationGetCurrentPage() + 1) * this.paginationPageSize >
      this.totaldata
        ? this.totaldata
        : (this.gridApi.paginationGetCurrentPage() + 1) *
          this.paginationPageSize;
  }
}
