import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { faShareFromSquare } from '@fortawesome/free-solid-svg-icons';
import { ColDef } from 'ag-grid-community';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { RefreshComponent } from 'src/app/core/grid-renderer/refresh/refresh.component';
import { TrialOrderHistory } from 'src/app/core/model/trial-order.model';
import { TrialOrdersService } from 'src/app/services/event-and-orders/trial-orders.service';
import { ExcelService } from 'src/app/services/excel.service';

@Component({
  selector: 'app-display-history',
  templateUrl: './display-history.component.html',
  styleUrls: ['./display-history.component.scss'],
})
export class DisplayHistoryComponent implements OnInit {
  columnDefs: ColDef[] = [];
  displayRowData: TrialOrderHistory[] = [];
  successMessage: string | undefined = undefined;
  errorMessage: string | undefined = undefined;
  faShareFromSquare = faShareFromSquare;
  eventId = '';

  constructor(
    private readonly ngxUiLoaderService: NgxUiLoaderService,
    private readonly trialOrderervice: TrialOrdersService,
    private readonly excelService: ExcelService
  ) {}

  ngOnInit(): void {
    this.getColDefs();
    this.getOrderHistoryData();
  }

  getColDefs() {
    this.columnDefs = [
      {
        floatingFilterComponent: RefreshComponent,
        floatingFilterComponentParams: { suppressFilterButton: true },
        minWidth: 60,
        maxWidth: 60,
      },
      {
        headerName: 'ORDER TRACKING NUMBER',
        field: 'orderTrackingNumber',
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        sortable: true,
        unSortIcon: true,
        autoHeight: true,
        minWidth: 150,
      },
      {
        headerName: 'SUP CODE',
        field: 'supplierCode',
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        sortable: true,
        unSortIcon: true,
        autoHeight: true,
        minWidth: 90,
      },
      {
        headerName: 'SUPPLIER NAME',
        field: 'supplierName',
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        sortable: true,
        unSortIcon: true,
        autoHeight: true,
        minWidth: 230,
      },
      {
        headerName: 'PART NUMBER',
        field: 'partNumber',
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        sortable: true,
        unSortIcon: true,
        autoHeight: true,
        minWidth: 170,
      },
      {
        headerName: 'PART NAME',
        field: 'partName',
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        sortable: true,
        unSortIcon: true,
        autoHeight: true,
        minWidth: 180,
      },
      {
        headerName: 'COLUMN NAME',
        field: 'columnName',
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        sortable: true,
        unSortIcon: true,
        autoHeight: true,
        minWidth: 190,
      },
      {
        headerName: 'PREVIOUS VALUE',
        field: 'previousValue',
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        sortable: true,
        unSortIcon: true,
        autoHeight: true,
        minWidth: 160,
      },
      {
        headerName: 'NEW VALUE',
        field: 'newValue',
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        sortable: true,
        unSortIcon: true,
        autoHeight: true,
        minWidth: 160,
      },
      {
        headerName: 'USER',
        field: 'createdByUser',
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        sortable: true,
        unSortIcon: true,
        autoHeight: true,
        minWidth: 120,
      },
      {
        headerName: 'DATE MODIFIED',
        field: 'dateModified',
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        sortable: true,
        unSortIcon: true,
        autoHeight: true,
        minWidth: 210,
      },
    ];
  }

  getOrderHistoryData() {
    this.eventId = String(sessionStorage.getItem('eventId'));
    this.ngxUiLoaderService.start('eventList');
    this.trialOrderervice.getOrderHistory(this.eventId)?.subscribe({
      next: (result) => {
        this.ngxUiLoaderService.stop('eventList');
        this.displayRowData = result;
      },
      error: (err) => {
        this.ngxUiLoaderService.stop('eventList');
        const error = err as HttpErrorResponse;
        this.errorMessage = error.error.error;
        setTimeout(() => {
          this.errorMessage = undefined;
          this.successMessage = undefined;
        }, 5000);
      },
    });
  }

  exportOrderHistory() {
    const headers = [
      'ORDER TRACKING NO',
      'SUPPLIER CODE',
      'SUPPLIER NAME',
      'PART NO',
      'PART NAME',
      'COLUMN NAME',
      'PREVIOUS VALUE',
      'NEW VALUE',
      'USER NAME',
      'DATE MODIFIED',
    ];
    const exportData: TrialOrderHistory[] = [];
    this.displayRowData.forEach((e) => {
      const obj = {
        orderTrackingNumber: e.orderTrackingNumber,
        supplierCode: e.supplierCode,
        supplierName: e.supplierName,
        partNumber: e.partNumber,
        partName: e.partName,
        columnName: e.columnName,
        previousValue: e.previousValue,
        newValue: e.newValue,
        createdByUser: e.createdByUser,
        dateModified: e.dateModified,
      };
      exportData.push(obj);
    });
    try {
      this.excelService.exportAsExcelFile(
        exportData,
        this.generateFileName(this.displayRowData[0].projectCode as string),
        headers
      );
    } catch (error) {
      if (error instanceof Error) {
        this.errorMessage = error.message;
      } else {
        this.errorMessage = 'Something went wrong while exporting file.';
      }
    }
  }

  generateFileName(projectCode: string) {
    if (!projectCode) {
      throw new Error(
        'Project code is probably empty or null to generate file.'
      );
    }
    const now = new Date();
    const day = String(now.getDate()).padStart(2, '0');
    const month = String(now.getMonth() + 1).padStart(2, '0');
    const year = now.getFullYear();
    const formattedDate = `${month}${day}${year}`;
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    const seconds = String(now.getSeconds()).padStart(2, '0');
    const formattedTime = `${hours}${minutes}${seconds}`;
    return `OrderHistory_${projectCode}_${formattedDate}_${formattedTime}`;
  }
}
