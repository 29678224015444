import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ColDef } from 'ag-grid-community';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { CommentRendererComponent } from 'src/app/core/grid-renderer/comment-expansion/comment-renderer';
import { EditConfigurationComponent } from 'src/app/core/grid-renderer/edit-configuration/edit-configuration.component';
import { Configuration } from 'src/app/core/model/manage-configurations.model';
import { ManageConfigurationsService } from 'src/app/services/tmna-namc/manage-configurations.service';
import { RefreshComponent } from '../../../core/grid-renderer/refresh/refresh.component';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-manage-configurations',
  templateUrl: './manage-configurations.component.html',
  styleUrls: ['./manage-configurations.component.scss'],
})
export class ManageConfigurationsComponent implements OnInit {
  successMessage: string | undefined = undefined;
  errorMessage: string | undefined = undefined;
  checkEditable = false;
  columnDefs: ColDef[] = [];
  displayRowData: Configuration[] = [];

  constructor(
    private readonly router: Router,
    private readonly ngxUiLoaderService: NgxUiLoaderService,
    private readonly configService: ManageConfigurationsService
  ) {}

  ngOnInit(): void {
    const message = this.configService.getMessage();
    if (message?.message) {
      message.isError
        ? (() => (this.errorMessage = message.message))()
        : (() => (this.successMessage = message.message))();
      this.settingTimeOut();
      this.configService.setMessage();
    }
    this.loadConfigurationDataFetch();
    this.getColDefs();
  }

  getColDefs() {
    this.columnDefs = [
      {
        headerName: 'CONFIG ID',
        field: 'configId',
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        filterParams: {
          filterOptions: ['contains', 'notContains'], // Specify filter options
          textFormatter: (value: string) =>
            value.toString().trim().toLowerCase(), // Trim and format text
          filter: (filterValue: string, cellValue: string) => {
            const trimmedFilterValue = filterValue.trim().toLowerCase();
            return cellValue.trim().toLowerCase().includes(trimmedFilterValue);
          },
        },
        sortable: true,
        unSortIcon: true,
        autoHeight: true,
        minWidth: 90,
        maxWidth: 90,
      },
      {
        headerName: 'CONFIGURATION KEY',
        field: 'configKeyName',
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        filterParams: {
          filterOptions: ['contains', 'notContains'], // Specify filter options
          textFormatter: (value: string) => value.trim().toLowerCase(), // Trim and format text
          filter: (filterValue: string, cellValue: string) => {
            const trimmedFilterValue = filterValue.trim().toLowerCase();
            return cellValue.trim().toLowerCase().includes(trimmedFilterValue);
          },
        },
        sortable: true,
        unSortIcon: true,
        autoHeight: true,
        minWidth: 290,
        maxWidth: 290,
        cellStyle: { cursor: 'pointer' },
        cellRenderer: CommentRendererComponent,
      },
      {
        headerName: 'CONFIGURATION VALUE',
        field: 'configKeyValue',
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        filterParams: {
          filterOptions: ['contains', 'notContains'], // Specify filter options
          textFormatter: (value: string) => value.trim().toLowerCase(), // Trim and format text
          filter: (filterValue: string, cellValue: string) => {
            const trimmedFilterValue = filterValue.trim().toLowerCase();
            return cellValue.trim().toLowerCase().includes(trimmedFilterValue);
          },
        },
        sortable: true,
        unSortIcon: true,
        autoHeight: true,
        wrapText: true,
        cellStyle: { cursor: 'pointer' },
        minWidth: 120,
        maxWidth: 340,
        cellRenderer: CommentRendererComponent,
      },
      {
        headerName: 'CONFIGURATION DESCRIPTION',
        field: 'commentsText',
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        filterParams: {
          filterOptions: ['contains', 'notContains'], // Specify filter options
          textFormatter: (value: string) => value.trim().toLowerCase(), // Trim and format text
          filter: (filterValue: string, cellValue: string) => {
            const trimmedFilterValue = filterValue.trim().toLowerCase();
            return cellValue.trim().toLowerCase().includes(trimmedFilterValue);
          },
        },
        sortable: true,
        unSortIcon: true,
        autoHeight: true,
        minWidth: 120,
      },
      {
        headerName: 'IS EDITABLE',
        field: 'isEditable',
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        filterParams: {
          filterOptions: ['contains', 'notContains'], // Specify filter options
          textFormatter: (value: string) => value.trim().toLowerCase(), // Trim and format text
          filter: (filterValue: string, cellValue: string) => {
            const trimmedFilterValue = filterValue.trim().toLowerCase();
            return cellValue.trim().toLowerCase().includes(trimmedFilterValue);
          },
        },
        sortable: true,
        unSortIcon: true,
        autoHeight: true,
        minWidth: 130,
        maxWidth: 130,
      },
      {
        headerName: 'ACTION',
        floatingFilterComponent: RefreshComponent,
        floatingFilterComponentParams: { suppressFilterButton: true },
        cellRenderer: EditConfigurationComponent,
        minWidth: 90,
        maxWidth: 90,
      },
    ];
  }

  loadConfigurationDataFetch() {
    this.ngxUiLoaderService.start('configListLoad');
    this.configService.getConfigurationList().subscribe({
      next: (result) => {
        this.ngxUiLoaderService.stop('configListLoad');
        this.displayRowData = [];
        this.displayRowData = result;
      },
      error: (err) => {
        this.ngxUiLoaderService.stop('configListLoad');
        const error = err as HttpErrorResponse;
        this.errorMessage = error.error;
        this.settingTimeOut();
      },
    });
  }

  settingTimeOut() {
    setTimeout(() => {
      this.errorMessage = undefined;
      this.successMessage = undefined;
    }, 5000);
  }
}
