import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { TrialOrdersService } from 'src/app/services/event-and-orders/trial-orders.service';
import { TrialOrderDetails } from '../../model/trial-order.model';

@Component({
  selector: 'app-supplier-comments-action',
  templateUrl: './supplier-comments-action.component.html',
  styleUrls: ['./supplier-comments-action.component.scss'],
})
export class SupplierCommentsActionComponent implements OnInit {
  successMessage: string | undefined = undefined;
  errorMessage: string | undefined = undefined;
  supplierComment = '';
  commentLengthCheck = false;
  flagValue = 'S';
  constructor(
    public dialogRef: MatDialogRef<SupplierCommentsActionComponent>,
    @Inject(MAT_DIALOG_DATA)
    public dataStore: { selectedOrder: TrialOrderDetails[] },
    private readonly ngxUiLoaderService: NgxUiLoaderService,
    private readonly trialOrderService: TrialOrdersService
  ) {}

  ngOnInit(): void {
    //no work
    this.supplierComment = this.dataStore?.selectedOrder[0]?.supplierComment;
    this.commentLengthCheck = this.supplierComment?.length === 0;
  }

  closeDialog() {
    this.dialogRef.close();
  }

  onCommentChange(event: string) {
    this.commentLengthCheck = event.length === 0;
    this.dataStore.selectedOrder[0].supplierComment = event;
  }

  updateSupplierComments() {
    //Now work
    this.ngxUiLoaderService.start('supplierComment');
    this.trialOrderService
      .updateComments(this.dataStore.selectedOrder, this.flagValue)
      .subscribe({
        next: (result) => {
          this.ngxUiLoaderService.stop('supplierComment');
          if(result.success.length !==0){
            this.dialogRef.close('Success');
          }
          else{
            this.dialogRef.close('Error');
          }
        },
        error: (err) => {
          this.ngxUiLoaderService.stop('supplierComment');
          const error = err as HttpErrorResponse;
          this.errorMessage = error.error;
          this.settingTimeOut();
        },
      });
  }

  settingTimeOut() {
    setTimeout(() => {
      this.errorMessage = undefined;
      this.successMessage = undefined;
    }, 5000);
  }
}
